import {
  Box,
  Grid,
  Typography,
  Link
} from '@mui/joy';
import Container from '../components/Container';
import CreateSubscriptionForm from '../components/CreateSubscriptionForm';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';

export default function CreateSubscription() {

  return (
    <Box>
      <header className="header-wrapper">
        <Box className="left-wrapper">
            <a href="/login/admin"><img src={Logo} alt="All Day TA" className="logo" /></a>
            <a href="/login/admin"><img src={LogoMobile} alt="All Day TA" className="logo-mobile" /></a>
        </Box>
      </header>
      <Container container spacing={2}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography level="h1">Create Subscriptions</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography level="title-sm">Need to create a faculty or department?&nbsp;
            <Link href="/create-org">
              Create faculty or department instead
            </Link>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <CreateSubscriptionForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
    
  );
}
