import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Autocomplete,
  AutocompleteOption,
  Select,
  Option,
  Alert,
} from "@mui/joy";
import { getAllTierIds, getTierDetails } from "../config/subscriptionConfig";

export default function CreateSubscriptionForm() {
  const [organizations, setOrganizations] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [admins, setAdmins] = useState([]);
  const defaultState = {
    organization: null,
    faculty: null,
    department: null,
    admin: null,
    type: "individual",
    startDate: new Date().toISOString().split("T")[0], // Default to current date
    tier: null,
  };
  const [formInputs, setFormInputs] = useState(defaultState);
  const defaultErrors = {
    organization: false,
    admin: false,
    type: false,
    startDate: false,
    tier: false,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const types = {
    individual: "individual",
    department: "department",
    faculty: "faculty",
    organization: "institution",
  };
  const tiers = getAllTierIds().map((id) => ({
    id: id,
    name: getTierDetails(id).name,
  }));

  const resetForm = () => {
    setFormInputs(defaultState);
    setErrors(defaultErrors);
  };

  useEffect(() => {
    getOrgs();
  }, []);

  const getOrgs = async () => {
    try {
      const response = await axios.get("/api/organizations/all");

      const processedData = response.data
        .map((item) => ({
          id: item.org_id,
          name: item.org_name,
          org_contact: item.org_contact,
          org_email: item.org_email,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setOrganizations(processedData);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const getFaculties = async (org_id) => {
    try {
      if (!org_id) return;

      const response = await axios.get(`/api/faculty?org_id=${org_id}`);

      const processedData = response.data.faculties
        .map((item) => ({
          id: item.id,
          org_id: item.org_id,
          name: item.name,
          owner_id: item.owner_id,
          owner_name: item.owner_name,
          owner_email: item.owner_email,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

      setFaculties(processedData);
    } catch (error) {
      console.error("Error fetching faculties:", error);
    }
  };

  const getDepartments = async (faculty_id) => {
    try {
      if (!faculty_id) return;

      const response = await axios.get(
        `/api/department?faculty_id=${faculty_id}`
      );

      const processedData = response.data.departments
        .map((item) => ({
          id: item.id,
          faculty_id: item.faculty_id,
          name: item.name,
          owner_id: item.owner_id,
          owner_name: item.owner_name,
          owner_email: item.owner_email,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)); // alphabetical sort;

      setDepartments(processedData);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const getAdmins = async (org_id) => {
    try {
      const response = await axios.get(`/api/admin?org_id=${org_id}`);
      if (response.status === 200) {
        setAdmins(response.data.admins);
        if (response.data.admins.length === 0) {
          setErrors({ ...errors, admin: true });
        }
      } else {
        console.error("Failed to fetch admins", response.data);
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const handleSave = async () => {
    const data = {
      org_id: formInputs?.organization?.id,
      faculty_id: formInputs?.faculty?.id,
      department_id: formInputs?.department?.id,
      admin_id: formInputs?.admin?.id,
      type: formInputs.type,
      start_date: formInputs.startDate,
      tier_id: formInputs.tier,
    };

    const newErrors = {
      organization: !data.org_id,
      admin: !data.admin_id,
      type: !data.type,
      startDate: !data.start_date,
      tier: !data.tier_id,
    };

    setErrors(newErrors);

    // If there are any errors, stop the submission
    if (Object.values(newErrors).some((hasError) => hasError)) {
      return;
    }

    const reqData = new FormData();
    reqData.append("subData", JSON.stringify(data));

    try {
      const response = await axios.post("/api/create-subscription", data);

      if (response.status === 200) {
        setMessage("Subscription created successfully!");
        setIsError(false);
        resetForm();
      } else {
        throw await response.data.message;
      }
    } catch (error) {
      setMessage("Error creating subscription: " + error);
      setIsError(true);
      console.error("Error details:", error);
    }
  };

  const handleOrgUpdate = (newValue) => {
    setFormInputs((prev) => ({ ...prev, organization: newValue }));
    handleFacultyUpdate(defaultState.faculty);
    handleDepartmentUpdate(defaultState.department);
    handleAdminUpdate(defaultState.admin);
    getFaculties(newValue?.id);
    getAdmins(newValue?.id);
    setErrors((prev) => ({ ...prev, organization: false }));
  };

  const handleFacultyUpdate = (newValue) => {
    setFormInputs((prev) => ({ ...prev, faculty: newValue }));
    handleDepartmentUpdate(defaultState.department);
    getDepartments(newValue?.id);
  };

  const handleDepartmentUpdate = (newValue) => {
    setFormInputs((prev) => ({ ...prev, department: newValue }));
  };

  const handleTypeUpdate = (newValue) => {
    let newOwner, newTier;
    if (newValue === types.organization) {
      newOwner = admins.find(
        (a) =>
          a.name === formInputs.organization.org_contact &&
          a.email === formInputs.organization.org_email
      );
      newTier = 5;
    } else if (newValue !== types.individual) {
      const type = newValue === types.organization ? "organization" : newValue;
      newOwner = admins.find((a) => a.id === formInputs[type].owner_id);
      newTier = 5;
    } else {
      newTier = 1;
    }
    setFormInputs((prev) => ({ ...prev, type: newValue }));
    handleAdminUpdate(newOwner || formInputs.admin);
    handleTierUpdate(newTier || formInputs.tier);
  };

  const handleTierUpdate = (newValue) => {
    setFormInputs((prev) => ({ ...prev, tier: newValue }));
    setErrors((prev) => ({ ...prev, tier: false }));
  };

  const handleAdminUpdate = (newValue) => {
    setFormInputs((prev) => ({ ...prev, admin: newValue }));
    setErrors((prev) => ({ ...prev, admin: false }));
  };

  return (
    <Stack direction="column" spacing={3}>
      {/* Org */}
      <FormControl error={errors.organization}>
        <FormLabel>Organization</FormLabel>
        <Autocomplete
          variant="outlined"
          color={errors.organization ? "danger" : "primary"}
          options={organizations}
          value={formInputs.organization}
          placeholder="Select an organization"
          getOptionLabel={(option) => option.name}
          onChange={(e, newValue) => handleOrgUpdate(newValue)}
          renderOption={(props, option) => {
            const { key, ...restProps } = props; // Extract and exclude the key from props
            return (
              <AutocompleteOption key={key} {...restProps}>
                {option.name}
              </AutocompleteOption>
            );
          }}
        />
        <FormHelperText>
          {errors.organization ? "Organization is required" : ""}
        </FormHelperText>
      </FormControl>
      {/* Faculty */}
      <FormControl>
        <FormLabel>Faculty</FormLabel>
        <Autocomplete
          variant="outlined"
          disabled={!formInputs.organization}
          options={faculties}
          value={formInputs.faculty}
          placeholder="Select an faculty"
          getOptionLabel={(option) => option.name}
          onChange={(e, newValue) => handleFacultyUpdate(newValue)}
          renderOption={(props, option) => {
            const { key, ...restProps } = props; // Extract and exclude the key from props
            return (
              <AutocompleteOption key={key} {...restProps}>
                {option.name}
              </AutocompleteOption>
            );
          }}
        />
        <FormHelperText>
          {!formInputs.organization ? "Select an Organization to enable" : ""}
        </FormHelperText>
      </FormControl>
      {/* Department */}
      <FormControl>
        <FormLabel>Department</FormLabel>
        <Autocomplete
          variant="outlined"
          disabled={!formInputs.faculty}
          options={departments}
          value={formInputs.department}
          placeholder="Select an department"
          getOptionLabel={(option) => option.name}
          onChange={(e, newValue) => handleDepartmentUpdate(newValue)}
          renderOption={(props, option) => {
            const { key, ...restProps } = props; // Extract and exclude the key from props
            return (
              <AutocompleteOption key={key} {...restProps}>
                {option.name}
              </AutocompleteOption>
            );
          }}
        />
        <FormHelperText>
          {!formInputs.faculty ? "Select an Faculty to enable" : ""}
        </FormHelperText>
      </FormControl>
      {/* Type */}
      <FormControl error={errors.type}>
        <FormLabel>Type</FormLabel>
        <Select
          defaultValue={types.individual}
          color={errors.type ? "danger" : "primary"}
          onChange={(e, newValue) => handleTypeUpdate(newValue)}
        >
          {Object.keys(types).map((type, i) => (
            <Option
              key={i}
              value={types[type]}
              disabled={!formInputs[type] && type !== types.individual}
            >
              {types[type].charAt(0).toUpperCase() + types[type].slice(1)}
            </Option>
          ))}
        </Select>
        <FormHelperText>{errors.type ? "Type is required" : ""}</FormHelperText>
      </FormControl>
      {/* Tier */}
      <FormControl error={errors.tier}>
        <FormLabel>Tier</FormLabel>
        <Select
          color={errors.tier ? "danger" : "primary"}
          value={formInputs.tier}
          disabled={formInputs.type !== types.individual}
          onChange={(e, newValue) => handleTierUpdate(newValue)}
        >
          {tiers.map((tier) => (
            <Option
              key={tier.id}
              value={tier.id}
              disabled={tier.id === 5 && formInputs.type === types.individual}
            >
              {tier.name}
            </Option>
          ))}
        </Select>
        <FormHelperText>
          {errors.tier ? "Tier is required" : ""}
          {formInputs.type !== types.individual
            ? `Locked because type is ${formInputs.type}`
            : ""}
        </FormHelperText>
      </FormControl>
      {/* Admin */}
      <FormControl error={errors.admin}>
        <FormLabel>Admin</FormLabel>
        <Autocomplete
          variant="outlined"
          disabled={
            (!formInputs.organization || formInputs.type !== types.individual) && formInputs.type !== types.organization
          }
          options={admins}
          value={formInputs.admin}
          placeholder="Select an Admin"
          color={errors.admin ? "danger" : "primary"}
          getOptionLabel={(option) => option.name}
          onChange={(e, newValue) => handleAdminUpdate(newValue)}
          renderOption={(props, option) => {
            const { key, ...restProps } = props; // Extract and exclude the key from props
            return (
              <AutocompleteOption key={key} {...restProps}>
                {option.name}
              </AutocompleteOption>
            );
          }}
        />
        <FormHelperText>
          {errors.admin ? "Admin is required. " : ""}
          {formInputs.organization && admins.length === 0
            ? "This organization has no admins. "
            : ""}
          {!formInputs.organization ? "Select an Organization to enable. " : ""}
          {formInputs.type !== types.individual && formInputs.type !== types.organization
            ? `Locked to ${formInputs.type} owner. `
            : ""}
        </FormHelperText>
      </FormControl>
      {/* Start Date */}
      <FormControl error={errors.startDate}>
        <FormLabel>Start Date</FormLabel>
        <Input
          type="date"
          value={formInputs.startDate}
          color={errors.startDate ? "danger" : "primary"}
          onChange={(e) => {
            setFormInputs({ ...formInputs, startDate: e.target.value });
            setErrors({ ...errors, startDate: false });
          }}
          placeholder="Select a start date"
        />
        <FormHelperText>
          {errors.startDate ? "Start date is required" : ""}
        </FormHelperText>
      </FormControl>
      {message && (
        <Alert
          color={isError ? "danger" : "success"}
          sx={{ mb: 2 }}
          role="alert"
          aria-live="polite"
        >
          {message}
        </Alert>
      )}
      <Box
        mt={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleSave}>Create</Button>
      </Box>
    </Stack>
  );
}
