import React from "react";
import { useCallback, useEffect, useState, useRef } from "react";
import axios from 'axios';
import {
  Box,
  Button,
  Drawer,
  Stack,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Autocomplete,
  ListItem,
  ListDivider,
  IconButton
} from '@mui/joy';
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

export default function CreateDepartmentDrawer({ org_id, faculty_id, getDepartments, setMessage, setIsError, initState }) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(initState?.name || '');
  const [owner, setOwner] = useState(initState ? {id: initState.owner_id, name: initState.owner_name, email: initState.owner_email} : null);
  const [admins, setAdmins] = useState([]);
  const [errors, setErrors] = useState({
    name: false,
    owner: false
  });
  const addButtonRef = useRef(null);
  const closeButtonRef = useRef(null);

  const fetchAdmins = async (org_id) => {
    try {
      const response = await axios.get(`/api/admin?org_id=${org_id}`);
      if (response.status === 200) {
        setAdmins(response.data.admins);
      } else {
        console.error("Failed to fetch admins", response.data);
      }
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const resetForm = useCallback(() => {
    setName(initState?.name || '');
    setOwner(initState ? {id: initState.owner_id, name: initState.owner_name, email: initState.owner_email} : null);
    setErrors((prevErrors) =>
      Object.keys(prevErrors).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {})
    );
  }, [initState]);

  const toggleDrawer = useCallback((inOpen) => {
    setOpen(inOpen);
    if (inOpen) {
      fetchAdmins(org_id)
      setTimeout(() => closeButtonRef.current?.focus(), 100);
    } else {
      addButtonRef.current?.focus();
      setTimeout(() => resetForm(), 100);
    }
  }, [org_id, resetForm]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        toggleDrawer(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [toggleDrawer]);

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (errors.name) {
      setErrors(prev => ({ ...prev, name: false }));
    }
  };

  const handleOwnerChange = (e, newValue) => {
    setOwner(newValue);
    if (errors.owner) {
      setErrors(prev => ({ ...prev, owner: false }));
    }
  };

  const handleSave = async () => {
    const newErrors = {
      name: !name.trim(),
      owner: !owner,
    };

    setErrors(newErrors);

    // If there are any errors, stop the submission
    if (newErrors.name || newErrors.owner) {
      return;
    }

    const departmentData = {
      faculty_id,
      name,
      owner_id: owner.id,
    };
  
    const formData = new FormData();
    formData.append('departmentData', JSON.stringify(departmentData));
  
    try {
      let response;
      if(initState) { // Update
        response = await axios.patch(`/api/department/${initState.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      } else { // Create
        response = await axios.post('/api/department', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      if (response.status === 200) {
        setMessage(initState ? 'Department updated successfully!' : 'Department created successfully!');
        getDepartments(faculty_id) // force department table to re-render
        setIsError(false);
      }
      
    } catch (error) {
      setMessage(initState ? ('Error updating department: ' + error) : ('Error creating department: ' + error));
      setIsError(true);
      console.error('Error details:', error);
    } finally {
      toggleDrawer(false)
    }
  };

  return (
    <Box mt={1}>
      {initState ? (
        <IconButton 
          variant="plain" 
          onClick={() => toggleDrawer(true)}
          aria-label={`Edit Action`}
        >
          <CreateIcon />
        </IconButton>
      ) : (
        <Button 
          variant="soft" 
          color="neutral" 
          fullWidth
          startDecorator={<AddIcon />}
          onClick={() => toggleDrawer(true)}
          ref={addButtonRef}
          aria-haspopup="true"
          aria-expanded={open}
          aria-label="Open create department form"
        >
          {"Add Department"}
        </Button>
      )}
      <Drawer open={open}
        anchor="right"
        variant="plain"
        aria-labelledby="drawer-title"
        onClose={() => toggleDrawer(false)}
      >
        <Box sx={{ padding:'2rem' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography
              id="drawer-title"
              level="component-md"
              color="primary"
            >
              Create Department
            </Typography>
            <Button
              variant="plain"
              className="close-btn"
              onClick={() => toggleDrawer(false)}
              color="primary"
              ref={closeButtonRef}
              aria-label="Close create department form"
            >
              <CloseIcon/>
            </Button>
          </Stack>
          <Stack direction="column" spacing={3}>
            <FormControl error={errors.name}>
              <FormLabel>Department Name</FormLabel>
              <Input 
                variant="outlined"
                color=
                  {errors.name ? "danger" : "primary"} 
                required
                value={name}
                onChange={handleNameChange}
              />
              <FormHelperText>
                {errors.name ? "Department name is required" : "" }
              </FormHelperText>
            </FormControl>
            <FormControl error={errors.owner}>
              <FormLabel>Owner</FormLabel>
              <Autocomplete
                variant="outlined"
                color={errors.owner ? "danger" : "primary"}
                value={owner}
                onChange={handleOwnerChange}
                options={admins}
                getOptionLabel={(option) => option.name}
                placeholder="Select an owner"
                slotProps={{
                  listbox: {
                    sx: { maxHeight: 200, overflow: "auto" },
                  },
                }}
                renderOption={(props, option) => {
                  const { key, ...restProps } = props; // Extract and exclude the key from props
                  return (
                    <React.Fragment key={option.id}>
                      <ListItem {...restProps}>
                        {option.name}
                      </ListItem>
                      <ListDivider />
                    </React.Fragment>
                  );
                }}
              />
              <FormHelperText>
                {errors.owner ? "Owner is required" : ""}
              </FormHelperText>
            </FormControl>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button onClick={handleSave}>Save</Button>
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </Box>
  );
}
