import React, { useState, useEffect } from 'react';
import { 
  FormControl,
  FormHelperText,  
  FormLabel, 
  Input 
} from '@mui/joy';

const CourseInfoFields = ({ 
  initialCourseName = '',
  initialProfessors = '',
  initialTeachingAssistants = '',
  initialTermName = '',
  onValidChange,
  isSubmitted = false ,
  disabled = false
}) => {
  const [courseName, setCourseName] = useState(initialCourseName);
  const [professors, setProfessors] = useState(initialProfessors);
  const [teachingAssistants, setTeachingAssistants] = useState(initialTeachingAssistants);
  const [termName, setTermName] = useState(initialTermName);
  const [errors, setErrors] = useState({
    courseName: false,
    professors: false,
  });

  const handleCourseNameChange = (e) => {
    const newCourseName = e.target.value;
    setCourseName(newCourseName);

    if (errors.courseName) {
      setErrors(prev => ({ ...prev, courseName: false }));
    }
    notifyParentOfChanges({
      ...getCurrentValues(),
      courseName: newCourseName
    });
  };

  const handleProfessorNamesChanges = (e) => {
    const newProfessorNames = e.target.value;
    setProfessors(newProfessorNames);

    if (errors.professors) {
      setErrors(prev => ({ ...prev, professors: false }));
    }
    notifyParentOfChanges({
      ...getCurrentValues(),
      professors: newProfessorNames
    });
  };

  const handleTeachingAssistantsChange = (e) => {
    const newTANames = e.target.value;
    setTeachingAssistants(newTANames);
    notifyParentOfChanges({
      ...getCurrentValues(),
      teachingAssistants: newTANames
    });
  };

  const handleTermNameChange = (e) => {
    const newTermName = e.target.value;
    setTermName(newTermName);
    notifyParentOfChanges({
      ...getCurrentValues(),
      termName: newTermName
    });
  };

  const getCurrentValues = () => ({
    courseName,
    professors,
    teachingAssistants,
    termName
  });

  const notifyParentOfChanges = (values) => {
    if (onValidChange) {
      onValidChange({
        ...values,
        isValid: Boolean(values.courseName.trim() && values.professors.trim())
      });
    }
  };

  // Update errors when isSubmitted changes
  useEffect(() => {
    if (isSubmitted) {
      const newErrors = {
        courseName: !courseName.trim(),
        professors: !professors.trim()
      };
      setErrors(newErrors);
    }
  }, [isSubmitted, courseName, professors]);

  // Update form when initial values change
  useEffect(() => {
    setCourseName(initialCourseName);
    setProfessors(initialProfessors);
    setTeachingAssistants(initialTeachingAssistants);
    setTermName(initialTermName);
  }, [initialCourseName, initialProfessors, initialTeachingAssistants, initialTermName]);

  return (
    <>
      <FormControl error={errors.courseName}>
        <FormLabel>Course Name (Required)</FormLabel>
        <Input
          variant="outlined"
          color={errors.courseName ? "danger" : "primary"}
          required
          value={courseName}
          onChange={handleCourseNameChange}
          disabled={disabled}
        />
        <FormHelperText>
          {errors.courseName
            ? "Course name is required"
            : "Course name as it will be presented to students"}
        </FormHelperText>
      </FormControl>
      <FormControl error={errors.professors}>
        <FormLabel>Professor Name(s) (Required)</FormLabel>
        <Input 
          variant="outlined" 
          color={errors.professors ? "danger" : "primary"} 
          required 
          value={professors} 
          onChange={handleProfessorNamesChanges}
          disabled={disabled}
        />
        <FormHelperText>
          {errors.professors
            ? "At least 1 professor name is required"
            : "Add multiple names separated with a comma"}
        </FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel>Teaching Assistant Name(s)</FormLabel>
        <Input
          variant="outlined"
          color="primary"
          value={teachingAssistants}
          onChange={handleTeachingAssistantsChange}
          disabled={disabled}
        />
        <FormHelperText>
          Add multiple names separated with a comma
        </FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel>Term Name</FormLabel>
        <Input 
          variant="outlined" 
          color="primary" 
          value={termName}
          onChange={handleTermNameChange}
          disabled={disabled}
        />
      </FormControl>
    </>
  );
};

export default CourseInfoFields;