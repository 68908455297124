import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getAssociatedCoursesListForAdmin } from "../CourseRetrieveAPI";
import TermsAcceptanceModal from '../components/TermsAcceptanceModal';


const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [orgId, setOrgId] = useState(null);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const authStatusRef = useRef(isAuthenticated);
    const navigate = useNavigate();

    const checkAuthStatus = useCallback(async () => {
        try {
            //console.log('Checking authentication status...');
            const response = await axios.get('/api/check-auth', { withCredentials: true });
            //console.log('Authentication status response:', response.data);
            authStatusRef.current = response.data.authenticated;
            setIsAuthenticated(response.data.authenticated);
            if (response.data.authenticated) {
                setOrgId(response.data.org_id);
                // Show terms modal if terms haven't been accepted
                if (!response.data.terms_accepted) {
                    setShowTermsModal(true);
                }
            } else {
                setOrgId(null);
            }
        } catch (error) {
            //console.error('Error checking authentication status:', error);
            setIsAuthenticated(false);
            setOrgId(null);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        checkAuthStatus();
    }, []);

    const handleAcceptTerms = async () => {
        try {
            await axios.post('/api/accept_terms');
            setShowTermsModal(false);
            // Optionally refresh auth status after terms acceptance
            await checkAuthStatus();
        } catch (error) {
            console.error('Error accepting terms:', error);
        }
    };

    const login = async (email, password) => {
        try {
            //console.log('Attempting to login...');
            const response = await axios.post('/api/login/special_admin_login', { email, password }, { withCredentials: true });
            //console.log('Login response:', response.data);
            if (response.data.success) {
                setIsAuthenticated(true);
                //console.log('Login successful');
                setOrgId(response.data.org_id);
                
                const associatedCourses = await getAssociatedCoursesListForAdmin();
                if (associatedCourses && associatedCourses.length > 0) {
                    navigate('/files');
                } else {
                    navigate('/create-course');
                }
                
            } else {
                //console.log('Login failed');
            }
            return response.data;
        } catch (error) {
            //console.error('Error during login:', error);
            return { message: 'Invalid credentials' };
        }
    };

    const logout = async () => {
        setLoading(true);
        try {
            //console.log('Attempting to logout...');
            const response = await axios.post('/api/logout', {}, { withCredentials: true });
            //console.log('Logout response:', response.data);
            if (response.status === 200) {
                setIsAuthenticated(false);
                //console.log('Logout successful');
                setOrgId(null);
                setShowTermsModal(false);
            } else {
                //console.log('Logout failed');
            }
            return response.data;
        } catch (error) {
            //console.error('Error during logout:', error);
            return { message: 'Error logging out' };
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, orgId, login, logout, loading, checkAuthStatus }}>
            {!loading && (
                <>
                    {children}
                    <TermsAcceptanceModal
                        open={showTermsModal}
                        onClose={handleAcceptTerms}  // Note: We don't allow closing without accepting
                    />
                </>
            )}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
