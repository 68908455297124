import { 
  Box,
  Button,
  Card,
  Chip, 
  ChipDelete,  
  FormControl, 
  FormLabel,
  Grid, 
  Input, 
  Option, 
  Select, 
  Stack,  
  Typography, 
} from '@mui/joy';

const FiltersSection = ({ 
  filters, 
  filterOptions, 
  setFilters, 
  newIpAddress, 
  setNewIpAddress, 
  handleAddIpAddress, 
  handleRemoveIpAddress,
  formatWeekLabel 
}) => {
  // Get current week and generate week options
  const getCurrentWeekOptions = () => {
    const currentDate = new Date();
    const currentWeek = Math.ceil((currentDate - new Date(currentDate.getFullYear(), 0, 1)) / 604800000);
    
    // Generate array of weeks up to current week
    return Array.from({ length: currentWeek }, (_, index) => ({
      weekNum: currentWeek - index, // Count down from current week
      dateRange: formatWeekLabel(currentWeek - index)
    }));
  };

  const weekOptions = getCurrentWeekOptions();

  return (
    <Card variant="outlined" sx={{ mb: 4, p: 2 }}>
      <Typography level="h2" sx={{ mb: 2 }}>Filter Questions</Typography>
      <Grid container spacing={2}>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>Organization</FormLabel>
            <Select
              value={filters.org_id}
              onChange={(_, newValue) => setFilters(prev => ({
                ...prev, 
                org_id: newValue, 
                admin_id: '', 
                course_id: '' 
              }))}
            >
              <Option key="org-all" value="">All Organizations</Option>
              {filterOptions.organizations.map(org => (
                <Option 
                  key={`org-${org.id}`} 
                  value={org.id.toString()} 
                  id={`org-option-${org.id}`}
                >
                  {org.name}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>Admin</FormLabel>
            <Select
              value={filters.admin_id}
              onChange={(_, newValue) => setFilters(prev => ({  
                ...prev, 
                admin_id: newValue, 
                course_id: '' 
              }))}
              disabled={!filters.org_id}
            >
              <Option key="admin-all" value="">All Admins</Option>
              {filterOptions.admins
                .filter(admin => !filters.org_id || admin.org_id.toString() === filters.org_id)
                .map(admin => (
                  <Option key={`admin-${admin.id}`} value={admin.id.toString()} id={`admin-option-${admin.id}`}>
                    {admin.name}
                  </Option>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={4}>
          <FormControl>
            <FormLabel>Course</FormLabel>
            <Select
              value={filters.course_id}
              onChange={(_, newValue) => setFilters(prev => ({ 
                ...prev, 
                course_id: newValue 
              }))}
              disabled={!filters.org_id}
            >
              <Option key="courses-all" value="">All Courses</Option>
              {filterOptions.courses
                .filter(course => !filters.org_id || course.org_id.toString() === filters.org_id)
                .map((course, index) => (
                  <Option key={`course-${course.org_id}-${course.id}-${index}`} value={course.id.toString()} id={`course-option-${course.org_id}-${course.id}`}>
                    {course.name}
                  </Option>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={3}>
          <FormControl>
            <FormLabel>Week</FormLabel>
            <Select
              value={filters.week || ''}
              onChange={(_, newValue) => setFilters(prev => ({
                ...prev,
                week: newValue
              }))}
            >
              <Option key="week-all" value="">All Weeks</Option>
              {weekOptions.map(week => (
                <Option 
                  key={`week-${week.weekNum}`} 
                  value={week.weekNum.toString()}
                >
                  {week.dateRange}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={9}>
          <FormControl>
            <FormLabel>Exclude IP Addresses</FormLabel>
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
              <Input
                placeholder="Enter IP address to exclude"
                value={newIpAddress}
                onChange={(e) => setNewIpAddress(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddIpAddress();
                  }
                }}
                sx={{ flex: 1 }}
              />
              <Button onClick={handleAddIpAddress}>Add</Button>
            </Stack>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {filters.excluded_ips.map((ip) => (
                <Chip
                  key={ip}
                  endDecorator={<ChipDelete onDelete={() => handleRemoveIpAddress(ip)} />}
                  size="md"
                >
                  {ip}
                </Chip>
              ))}
            </Box>
          </FormControl>
        </Grid>
      </Grid>
    </Card>
  );
};

export default FiltersSection;

