// this file is for editing a course
// Note: Safari requires special date handling due to its strict date parsing.
// The date utilities and validation in this component have been specifically
// designed to work across all browsers including Safari's stricter requirements.
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  DialogTitle, 
  DialogContent, 
  DialogActions,
  FormHelperText,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal, 
  ModalDialog, 
  ModalClose, 
  Snackbar,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import Container from "../components/Container";
import LoadingSkeleton from "../components/LoadingSkeletons";
import CourseInfoFields from '../components/form-elements/CourseInfoFields';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getOrgName } from '../OrgRetrieveAPI';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CourseCheck from '../components/CourseCheck';
import { slugify } from '../components/UrlHandler';

export default function CourseInfo({ headerRef }) {
  //console.log("CourseInfo component mounted");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [org_Id, setOrgId] = useState(null);
  const [course_Id, setCourseId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [modules, setModules] = useState([""]);
  const [courseName, setCourseName] = useState("");
  const [description, setDescription] = useState('');
  const [professors, setProfessors] = useState('');
  const [teachingAssistants, setTeachingAssistants] = useState("");
  const [termName, setTermName] = useState('');
  // Used for course URL
  const [orgName, setOrgName] = useState('');
  const [slugifiedOrgName, setSlugifiedOrgName] = useState('');
  const [courseUrl, setCourseUrl] = useState('');
  const [originalCourseUrl, setOriginalCourseUrl] = useState('');
  const [slugifiedUrl, setSlugifiedUrl] = useState('');
  const [editableCourseUrl, setEditableCourseUrl] = useState('');
  const [fixedUrlPart, setFixedUrlPart] = useState('');
  const [fullSlugifiedUrl, setFullSlugifiedUrl] = useState('');
  const [urlChanged, setUrlChanged] = useState(false);
  const [currentCourseUrl, setCurrentCourseUrl] = useState('');
  const [urlError, setUrlError] = useState("");
  //Alerts and errors
  const [statusMessage, setStatusMessage] = useState("");
  const [showSlugAlert, setShowSlugAlert] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errors, setErrors] = useState({
    courseName: false,
    professors: false,
  });

  useEffect(() => {
      document.title = "Edit Course Information - All Day TA";
  }, []);

  const retrieveCourseInfo = useCallback(async () => {
    //console.log("retrieveCourseInfo called");
    setIsLoading(true);
    try {
      const response = await axios.get("/api/course");
      //console.log("Response received from /api/course", response);
      if (response.data && response.data.length > 0) {
        //console.log("Response data:", response.data);
        const courseData = response.data[0];
        //console.log("Course data received:", courseData);

        setOrgId(courseData.org_id);
        setCourseId(courseData.course_id);
        setCourseName(courseData.course_name);
        setProfessors(courseData.professor_names.toString().replaceAll(",", ", "));
        setModules(courseData.topics);
        setTermName(courseData.term);
        setDescription(courseData.description);
        setCourseUrl(courseData.course_url);
        setTeachingAssistants(courseData.ta_names.toString().replaceAll(",", ", "));
        setCourseUrl(courseData.course_url);
        setOriginalCourseUrl(courseData.course_url);
        setCurrentCourseUrl(courseData.course_url);
        //console.log("Original course URL set:", courseUrl);

      } else {
        //console.log("Response data is empty");
      }
    } catch (error) {
      //console.error("Error fetching course info:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
       //console.log("useEffect called");
    retrieveCourseInfo();
    if (headerRef.current) {
        headerRef.current.setOnCourseChange(retrieveCourseInfo);
    }
  }, [headerRef, retrieveCourseInfo]);
  
  //Course URL
  useEffect(() => {
    const fetchOrgName = async () => {
      try {
        const name = await getOrgName();
        setOrgName(name);
        setSlugifiedOrgName(slugify(name));
      } catch (error) {
        console.error('Error fetching organization name:', error);
        // Handle the error appropriately in your UI
      }
    };

    fetchOrgName();
  }, []);

  useEffect(() => {
    if (courseUrl) {
      const urlParts = courseUrl.split('/');
      const editablePart = urlParts[urlParts.length - 1];
      const fixedPart = urlParts.slice(0, -1).join('/');
      setEditableCourseUrl(editablePart);
      setFixedUrlPart(fixedPart);
    }
  }, [courseUrl]);

  useEffect(() => {
    if (currentCourseUrl) {
      const urlParts = currentCourseUrl.split('/');
      const editablePart = urlParts[urlParts.length - 1];
      const fixedPart = urlParts.slice(0, -1).join('/');
      setEditableCourseUrl(editablePart);
      setFixedUrlPart(fixedPart);
      console.log("URL parts set:", { editablePart, fixedPart });
    }
  }, [currentCourseUrl]);

  const handleCourseUrlChange = (e) => {
    const newEditablePart = e.target.value;
    // Keep original text in input
    setEditableCourseUrl(newEditablePart);
    const newFullUrl = `${fixedUrlPart}/${newEditablePart}`;
    setCurrentCourseUrl(newFullUrl);

    // Show slugified preview in the alert for copy
    const slugifiedEditablePart = slugify(newEditablePart);
    const slugifiedFixedPart = slugify(fixedUrlPart);
    setFullSlugifiedUrl(`app.alldayta.com/${slugifiedFixedPart}/${slugifiedEditablePart}`);
    setShowSlugAlert(true);
    const hasChanged = newFullUrl !== originalCourseUrl;
    setUrlChanged(hasChanged);
    console.log("URL changed:", { newFullUrl, originalCourseUrl, hasChanged });
    setUrlError("");
  };

  const handleCourseUrlBlur = () => {
    if (editableCourseUrl !== originalCourseUrl) {
      setShowSlugAlert(true);
    }
  };

  const copyToClipboard = async () => {
      try {
          await navigator.clipboard.writeText(fullSlugifiedUrl);
          setSnackbarOpen(true);
      } catch (err) {
          //console.error('Failed to copy: ', err);
      }
  };

const handleSave = async () => {
  //console.log("Saving the updated course information...");
  setIsSubmitted(true);

  const newErrors = {
    courseName: !courseName.trim(),
    professors: !professors.trim(),
  };

  setErrors(newErrors);

  if (newErrors.courseName || newErrors.professors) {
    return;
  }

  const trimmedDescription = description.trim();

  const fullCourseUrl = `${slugifiedOrgName}/${slugifiedUrl}`;

  const courseData = {
    courseName,
    trimmedDescription,
    professors,
    teachingAssistants,
    modules,
    termName,
    courseUrl: urlChanged ? currentCourseUrl : undefined,
    urlChanged
  };

  //console.log("courseData prepared:", courseData);

  try {
    //console.log("Sending POST request to /api/course_info");
    const response = await axios.patch("/api/course_info", courseData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    //console.log("Response received:", response);

    if (response.status === 200) {
      const courseId = response.data.course.course_id;

      navigate("/files");
      setStatusMessage("Course data updated successfully");
      //console.log("Server response:", response);
      if (headerRef.current) {
        headerRef.current.refreshCourseList();
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 400 && error.response.data.error.includes("URL already exists")) {
      setUrlError("This URL already exists. Please choose a different one.");
      if (error.response.data.suggested_url) {
          setCurrentCourseUrl(error.response.data.suggested_url);
          const urlParts = error.response.data.suggested_url.split('/');
          setEditableCourseUrl(urlParts[urlParts.length - 1]);
          setFullSlugifiedUrl(`app.alldayta.com${error.response.data.suggested_url}`);
        }
    } else {
      setStatusMessage("Error saving course data: " + (error.response?.data?.message || error.message));
    }
  }
};

const handleDeleteCourse = async () => {
  try {
    //console.log(`Attempting to delete course: /api/delete/course/${org_Id}/${course_Id}`);
    const response = await axios.delete(`/api/delete/course/${org_Id}/${course_Id}`);
    if (response.status === 200) {
      setStatusMessage("Course deleted successfully");
      navigate("/files");
      if (headerRef.current) {
        headerRef.current.refreshCourseList();
      }
    } else {
      setStatusMessage("Error deleting course: " + response.statusText);
    }
  } catch (error) {
    setStatusMessage("Error deleting course: " + error.response.data.message);
    //console.error("Error details:", error.response.data);
  }
  setIsDeleteDialogOpen(false);
};

return (
  <Container>
    {isLoading ? (
      <LoadingSkeleton />
    ) : (
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ flexWrap: "wrap" }}
          >
            <Typography level="h1" id="edit-course-form">Edit Course Information</Typography>
          </Stack>
        </Grid>
        <CourseCheck> 
        <Grid
          xs={12}
          sm={6}
          pd={3}
          sx={{ boxSizing: "border-box", width: "100%" }}
        >
          <Stack direction="column" spacing={3}
            role="group"
            aria-labelledby="edit-course-form"
            >
            <CourseInfoFields 
              initialCourseName={courseName}
              initialProfessors={professors}
              initialTeachingAssistants={teachingAssistants}
              initialTermName={termName}
              isSubmitted={isSubmitted}
              onValidChange={({ courseName, professors, teachingAssistants, termName, isValid }) => {
                setCourseName(courseName);
                setProfessors(professors);
                setTeachingAssistants(teachingAssistants);
                setTermName(termName);
              }}
            />
          </Stack>
        </Grid>

        <Grid xs={12} sm={6}>
          <Stack direction="column" spacing={3}
            role="group"
            aria-labelledby="edit-course-form"
            >
            <FormControl>
              <FormLabel>Course Description</FormLabel>
              <Textarea variant="outlined" color="primary" minRows={4} value={description} onChange={(e) => setDescription(e.target.value)} data-testid="course-description" />
            </FormControl>
            <FormControl error={!!urlError}>
              <FormLabel>Course URL (Student Access)</FormLabel>
              <Stack
                direction={{ xs: 'column', sm: 'column', md:'column', lg:'row'}} 
                justifyContent="flex-start" 
                alignItems={{ xs: 'flex-start', lg:'center'}}
                mt={1}
                >
                  <Typography>app.alldayta.com{fixedUrlPart}/</Typography>
                  <Input
                    variant="outlined"
                    color={urlError ? "danger" : "primary"}
                    value={editableCourseUrl}
                    onChange={handleCourseUrlChange}
                    onBlur={handleCourseUrlBlur}
                    sx={{ display:'flex', flexGrow:'1'}}
                  />
              </Stack>
              {urlError && (
                <FormHelperText sx={{ color: 'danger' }}>
                  {urlError}
                </FormHelperText>
              )}
            </FormControl>
            {showSlugAlert && (
              <Alert
                sx={{ mt: 1 }}
                role="alert"
                aria-live="polite"
                endDecorator={
                  <Button
                    variant="plain"
                    color="neutral"
                    onClick={copyToClipboard}
                    endDecorator={<ContentCopyIcon />}
                  >
                    Copy
                  </Button>
                }
              >
                <strong>Your URL:</strong> {fullSlugifiedUrl}
              </Alert>
            )}
          </Stack>
        </Grid>

        <Grid
          xs={12}
          mb={4}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="plain" color="danger" onClick={() => setIsDeleteDialogOpen(true)} endDecorator={<DeleteOutlineIcon />}>
              Delete Course
            </Button>
            <Button variant="outlined" color="primary" onClick={() => navigate("/files")}>
              Cancel
            </Button>
            <Button variant="solid" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Typography>{statusMessage}</Typography>
        </Grid>
        </CourseCheck>
      </Grid>
    )}
    
    <Modal open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
      <ModalDialog>
        <DialogTitle>Confirm Course Deletion</DialogTitle>
        <ModalClose />
        <DialogContent>
          Are you sure you want to delete this course? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleDeleteCourse}>
            Delete
          </Button>
          <Button variant="outlined" color="neutral" onClick={() => setIsDeleteDialogOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
    <Snackbar
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        color="success"
        variant="soft"
        role="alert"
        aria-live="polite"
        className="url-copied"
        data-cy="copy-success-snackbar"
    >
        URL copied to clipboard!
    </Snackbar>
  </Container>
);
}
