import { useEffect, useState } from 'react';
import {
  Button,
  Sheet,
  Stack,
  Table,
  Typography,
} from '@mui/joy';
import ManageDepartmentTable from './ManageDepartmentTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import CreateFacultyDrawer from './CreateFacultyDrawer';

function Row({ row, org_id, getFaculties, setMessage, setIsError }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* Faculty */}
      <tr>
        <td style={{ width: "10%" }}>
          <Typography level="body-md" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.id}</Typography>
        </td>
        <td style={{ width: "30%" }}>
          <Typography level="body-md" sx={{ overflow: 'scroll', textOverflow: 'ellipsis' }}>{row.name}</Typography>
        </td>
        <td style={{ width: "20%" }}>
          <Typography level="body-md" sx={{ overflow: 'scroll', textOverflow: 'ellipsis' }}>{row.owner_name}</Typography>
        </td>
        <td style={{ width: "20%" }}>
          <Typography level="body-md" sx={{ overflow: 'scroll', textOverflow: 'ellipsis' }}>{row.owner_email}</Typography>
        </td>
        <td style={{ width: "20%" }}>
          <Stack direction={"row"} sx={{ justifyContent: "right" }}>
            <CreateFacultyDrawer org_id={org_id} getFaculties={getFaculties} setMessage={setMessage} setIsError={setIsError} initState={row} />
            <Button
              aria-label="expand row"
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => {
                setOpen(!open);
              }}
              endDecorator={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
              Departments
            </Button>
          </Stack>
        </td>
      </tr>
      <tr>
        <td style={{ height: 0, padding: 0, textAlign: 'left' }} colSpan={5}>
          {open && (
            <ManageDepartmentTable org_id={row.org_id} faculty_id={row.id} open={open} setMessage={setMessage} setIsError={setIsError} />
          )}
        </td>
      </tr>
    </>
  );
}

export default function TableCollapsibleRow( { org_id, open, setMessage, setIsError }) {
  const [faculties, setFaculties] = useState([]);

  const getFaculties = async (org_id) => {
    try {
        const response = await axios.get(`/api/faculty?org_id=${org_id}`);

        if (response.data.faculties.length === 0) {
          setFaculties([]);
          return;
        }
        
        const processedData = response.data.faculties.map((item) => ({
          id: item.id,
          org_id: item.org_id,
          name: item.name,
          owner_id: item.owner_id,
          owner_name: item.owner_name,  
          owner_email: item.owner_email, 
        })).sort((a, b) => a.name.localeCompare(b.name)); // alphabetical sort

      setFaculties(processedData);
    } catch (error) {
      console.error('Error fetching question logs:', error);
    }
  };

  // Functionality
  useEffect(() => {
    if(open) getFaculties(org_id);
  }, [open, org_id]);

  return (
    <Sheet
      variant="outlined"
      sx={{pl: 2, pb: 1, backgroundColor: 'var(--joy-palette-neutral-100)', wordWrap:'break-word', }}
    >
      <Table
        hoverRow
        aria-label="collapsible faculties table"
        size="md"
      >
      {/* Table Body Rows */} 
        <tbody>
          {faculties.map((row) => (
            <Row key={row.id} row={row} org_id={org_id} getFaculties={getFaculties} setMessage={setMessage} setIsError={setIsError} />
          ))}
        </tbody>
      </Table>
      <CreateFacultyDrawer org_id={org_id} getFaculties={getFaculties} setMessage={setMessage} setIsError={setIsError} />
    </Sheet>
  );
}