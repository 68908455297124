import React, { useState, useEffect } from 'react';
import { 
  FormControl,
  FormHelperText,  
  FormLabel, 
  Input 
} from '@mui/joy';

const ProfessorContactFields = ({ 
  initialName = '',
  initialEmail = '',
  onValidChange,
  isSubmitted = false 
}) => {
  const [professorName, setProfessorName] = useState(initialName);
  const [professorEmail, setProfessorEmail] = useState(initialEmail);
  const [errors, setErrors] = useState({
    professorName: false,
    professorEmail: false
  });

  const isValidEmail = (email) => {
    // Basic email validation regex
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // Handle professor name change with validation
  const handleProfessorNameChange = (e) => {
    const newName = e.target.value;
    setProfessorName(newName);
    
    if (errors.professorName) {
      setErrors(prev => ({ ...prev, professorName: false }));
    }

    // Notify parent component of valid changes
    onValidChange({
      name: newName,
      email: professorEmail,
      isValid: Boolean(newName.trim() && (professorEmail.trim() && isValidEmail(professorEmail)))
    });
  };

  // Handle professor email change with validation
  const handleProfessorEmailChange = (e) => {
    const newEmail = e.target.value;
    setProfessorEmail(newEmail);
    
    if (errors.professorEmail) {
      setErrors(prev => ({ ...prev, professorEmail: false }));
    }

    // Notify parent component of valid changes
    onValidChange({
      name: professorName,
      email: newEmail,
      isValid: Boolean(professorName.trim() && (newEmail.trim() && isValidEmail(newEmail)))
    });
  };

  // Update errors when isSubmitted changes
  useEffect(() => {
    if (isSubmitted) {
      const newErrors = {
        professorName: !professorName.trim(),
        professorEmail: !professorEmail.trim() || !isValidEmail(professorEmail)
      };
      setErrors(newErrors);
    }
  }, [isSubmitted]);

  // Update form when initial values change
  useEffect(() => {
    setProfessorName(initialName);
    setProfessorEmail(initialEmail);
  }, [initialName, initialEmail]);

  return (
    <>
      <FormControl error={errors.professorName}>
        <FormLabel>Name</FormLabel>
        <Input 
          variant="outlined"
          color={errors.professorName ? "danger" : "primary"}
          required 
          value={professorName}
          onChange={handleProfessorNameChange}
        />
        <FormHelperText>
          {errors.professorName ? "Professor name is required" : null}
        </FormHelperText>
      </FormControl>

      <FormControl error={errors.professorEmail}>
        <FormLabel>Email</FormLabel>
        <Input 
          variant="outlined"
          color={errors.professorEmail ? "danger" : "primary"}
          required
          value={professorEmail}
          onChange={handleProfessorEmailChange}
          type="email"
        />
        <FormHelperText>
          {errors.professorEmail ? !professorEmail.trim() ? "Professor email is required" : "Professor email is invalid" : null}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default ProfessorContactFields;