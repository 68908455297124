import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Typography,
  Button,
  CircularProgress,
} from '@mui/joy';
import RefreshIcon from '@mui/icons-material/Refresh';
import Container from '../components/Container';
import MetricsOverview from '../components/analytics/MetricsOverview';
import WeeklyChart from '../components/analytics/WeeklyChart';
import FiltersSection from '../components/analytics/FiltersSection';
import QuestionsList from '../components/analytics/QuestionsList';
import { useWeekUtils } from '../components/analytics/WeekUtils';


export default function Analytics() {
  const [filters, setFilters] = useState({
    org_id: '',
    admin_id: '',
    course_id: '',
    start_time: '',
    end_time: '',
    week: '',
    excluded_ips: []
  });
  
  const [filterOptions, setFilterOptions] = useState({
    organizations: [],
    admins: [],
    courses: []
  });

  const [metrics, setMetrics] = useState({
    total: 0,
    syllabusQuestions: 0,
    followUps: 0,
    uniqueIPs: 0,
    languages: {},
    weeklyDistribution: {}
  });

  const [weeklyDistribution, setWeeklyDistribution] = useState({});

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastRefreshTime, setLastRefreshTime] = useState(null);
  const [refreshDuration, setRefreshDuration] = useState(null);

  const [questions, setQuestions] = useState([]);

  
  const { getWeekNumber, formatWeekLabel } = useWeekUtils();
  const [error, setError] = useState(null);
  const [newIpAddress, setNewIpAddress] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [isQuestionsLoading, setIsQuestionsLoading] = useState(false);
  const [isDistributionLoading, setIsDistributionLoading] = useState(false);
  const ITEMS_PER_PAGE = 10;

  // Add IP address to excluded list
  const handleAddIpAddress = () => {
    if (!newIpAddress) return;
    if (filters.excluded_ips.includes(newIpAddress)) {
      setError("IP address already in exclusion list");
      return;
    }

    setFilters(prev => ({
      ...prev,
      excluded_ips: [...prev.excluded_ips, newIpAddress]
    }));
    setNewIpAddress('');
    setError(null);
  };

  // Remove IP address from excluded list
  const handleRemoveIpAddress = (ipToRemove) => {
    setFilters(prev => ({
      ...prev,
      excluded_ips: prev.excluded_ips.filter(ip => ip !== ipToRemove)
    }));
  };

  // Fetch filter options
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const params = {
          ...(filters.org_id && { org_id: filters.org_id }),
          ...(filters.admin_id && { admin_id: filters.admin_id })
        };
        const response = await axios.get('/api/super/analytics/filters', { params });
        setFilterOptions(response.data);
      } catch (error) {
        setError("Error fetching filter options");
      }
    };
    fetchFilterOptions();
  }, [filters.org_id, filters.admin_id]);

  // Fetch weekly distribution data
  const fetchWeeklyDistribution = async () => {
    try {
      setIsDistributionLoading(true);
      const params = new URLSearchParams();
      
      // Add filters
      if (filters.org_id) params.append('org_id', filters.org_id);
      if (filters.admin_id) params.append('admin_id', filters.admin_id);
      if (filters.course_id) params.append('course_id', filters.course_id);
      
      // Add week and year if week filter is active
      if (filters.week) {
        const year = new Date().getFullYear();
        params.append('year', year);
      }

      // Add excluded IPs
      filters.excluded_ips.forEach(ip => {
        params.append('excluded_ips', ip);
      });

      const response = await axios.get(`/api/super/analytics/weekly-distribution?${params.toString()}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching weekly distribution:', error);
      return {}; // Return empty distribution on error
    } finally {
      setIsDistributionLoading(false);
    }
  };

  const fetchMetrics = async () => {
    try {
      const params = new URLSearchParams();
      if (filters.org_id) params.append('org_id', filters.org_id);
      if (filters.admin_id) params.append('admin_id', filters.admin_id);
      if (filters.course_id) params.append('course_id', filters.course_id);
      
      // Handle week filter
      if (filters.week) {
        const year = new Date().getFullYear();
        const weekStart = getDateOfWeek(parseInt(filters.week), year);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        params.append('start_time', weekStart.toISOString());
        params.append('end_time', weekEnd.toISOString());
      } else {
        if (filters.start_time) params.append('start_time', filters.start_time);
        if (filters.end_time) params.append('end_time', filters.end_time);
      }
      
      filters.excluded_ips.forEach(ip => {
        params.append('excluded_ips', ip);
      });
  
      const response = await axios.get(`/api/super/analytics/metrics?${params.toString()}`);
      return response.data;
    } catch (error) {
      throw new Error("Error fetching metrics");
    }
  };

  const fetchQuestions = async (page) => {
    try {
      const params = new URLSearchParams({
        page: page,
        per_page: ITEMS_PER_PAGE
      });
  
      if (filters.org_id) params.append('org_id', filters.org_id);
      if (filters.admin_id) params.append('admin_id', filters.admin_id);
      if (filters.course_id) params.append('course_id', filters.course_id);
      
      // Handle week filter
      if (filters.week) {
        const year = new Date().getFullYear();
        const weekStart = getDateOfWeek(parseInt(filters.week), year);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        params.append('start_time', weekStart.toISOString());
        params.append('end_time', weekEnd.toISOString());
      } else {
        if (filters.start_time) params.append('start_time', filters.start_time);
        if (filters.end_time) params.append('end_time', filters.end_time);
      }
      
      filters.excluded_ips.forEach(ip => {
        params.append('excluded_ips', ip);
      });
  
      const response = await axios.get(`/api/super/analytics/questions?${params.toString()}`);
      return response.data;
    } catch (error) {
      throw new Error("Error fetching questions");
    }
  };

  // Helper function to get date of a week number
  const getDateOfWeek = (week, year) => {
    const date = new Date(year, 0, 1);
    date.setDate(date.getDate() + (week - 1) * 7);
    return date;
  };

  // Handle page change
  const handlePageChange = async (newPage) => {
    try {
      setIsQuestionsLoading(true);
      const questionsData = await fetchQuestions(newPage);
      setQuestions(questionsData.questions);
      setTotalQuestions(questionsData.total_count);
      setTotalPages(questionsData.total_pages);
      setCurrentPage(questionsData.current_page);
    } catch (error) {
      setError("Error fetching questions");
    } finally {
      setIsQuestionsLoading(false);
    }
  };

  // Reset everything when any filter in filters changes
  const didDistributionFiltersChange = (prevFilters, currentFilters) => {
    return prevFilters.org_id !== currentFilters.org_id ||
      prevFilters.admin_id !== currentFilters.admin_id ||
      prevFilters.course_id !== currentFilters.course_id ||
      prevFilters.excluded_ips.length !== currentFilters.excluded_ips.length;
  };
  useEffect(() => {
    const prevFilters = JSON.parse(sessionStorage.getItem('prevFilters') || '{}');
    const shouldFetchDistribution = didDistributionFiltersChange(prevFilters, filters);
    const fetchData = async () => {
      setError(null);
      setIsQuestionsLoading(true);
      
      try {
        // Fetch distribution only if relevant filters changed
        if (shouldFetchDistribution) {
          setIsDistributionLoading(true);
          const weeklyDistData = await fetchWeeklyDistribution();
          setMetrics(prev => ({
            ...prev,
            weeklyDistribution: weeklyDistData || prev.weeklyDistribution
          }));
        }
  
        // Always fetch metrics and questions
        const [metricsData, questionsData] = await Promise.all([
          fetchMetrics(),
          fetchQuestions(1)
        ]);
  
        setMetrics(prev => ({
          ...prev,
          ...metricsData,
        }));
  
        if (questionsData) {
          setQuestions(questionsData.questions || []);
          setTotalQuestions(questionsData.total_count || 0);
          setTotalPages(questionsData.total_pages || 1);
          setCurrentPage(questionsData.current_page || 1);
        }
  
      } catch (error) {
        console.error('Fetch error:', error);
        setError("Error fetching data");
        setQuestions([]);
        setTotalQuestions(0);
        setTotalPages(1);
        setCurrentPage(1);
      } finally {
        setIsQuestionsLoading(false);
        setIsDistributionLoading(false);
      }
    };
  
    fetchData();
    
    // Store current filters for next comparison
    sessionStorage.setItem('prevFilters', JSON.stringify(filters));
  }, [
    filters.org_id,
    filters.admin_id,
    filters.course_id,
    filters.week,
    filters.start_time,
    filters.end_time,
    filters.excluded_ips.length
  ]);

  const handleRefreshMV = async () => {
    try {
      setIsRefreshing(true);
      setError(null);
      
      const response = await axios.post('/api/super/analytics/refresh-mv');
      
      if (response.data.success) {
        setLastRefreshTime(new Date().toLocaleTimeString());
        setRefreshDuration(response.data.execution_time);
        
        // Refetch all data
        const [metricsData, weeklyDistData] = await Promise.all([
          fetchMetrics(),
          fetchWeeklyDistribution()
        ]);
        
        setMetrics(prev => ({
          ...prev,
          ...metricsData,
          weeklyDistribution: weeklyDistData || prev.weeklyDistribution
        }));
        
        const questionsData = await fetchQuestions(1);
        if (questionsData) {
          setQuestions(questionsData.questions || []);
          setTotalQuestions(questionsData.total_count || 0);
          setTotalPages(questionsData.total_pages || 1);
          setCurrentPage(questionsData.current_page || 1);
        }
      }
    } catch (error) {
      setError("Error refreshing analytics data");
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <Container>
      <Box sx={{ p: 2 }}>
        {/* Header */}
        <Typography level="h1" sx={{ mb: 4 }}>Analytics Dashboard</Typography>

        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            startDecorator={isRefreshing ? <CircularProgress size="sm" /> : <RefreshIcon />}
            onClick={handleRefreshMV}
            disabled={isRefreshing}
            color="primary"
            variant="soft"
          >
            {isRefreshing ? 'Refreshing...' : 'Refresh Analytics Data Background'}
          </Button>
          {lastRefreshTime && (
            <Typography level="body-sm" sx={{ mt: 1, textAlign: 'right' }}>
              Last refreshed: {lastRefreshTime}
              {refreshDuration && ` (took ${refreshDuration}s)`}
            </Typography>
          )}
        </Box>

        {/* Error Alert */}
        {error && (
          <Alert color="danger" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {/* Filters Section */}
        <FiltersSection 
          filters={filters}
          filterOptions={filterOptions}
          setFilters={setFilters}
          newIpAddress={newIpAddress}
          setNewIpAddress={setNewIpAddress}
          handleAddIpAddress={handleAddIpAddress}
          handleRemoveIpAddress={handleRemoveIpAddress}
          formatWeekLabel={formatWeekLabel}
        />

        {metrics?.weeklyDistribution && (
          <WeeklyChart 
            distribution={metrics.weeklyDistribution}
            formatWeekLabel={formatWeekLabel}
            isLoading={isDistributionLoading}
          />
        )}

        <MetricsOverview 
          metrics={metrics}
          isLoading={isDistributionLoading}
        />
        
        <QuestionsList 
          filteredQuestions={questions}
          totalQuestions={totalQuestions}
          isLoading={isQuestionsLoading}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          hasMoreQuestions={currentPage < totalPages}
        />
      </Box>
    </Container>
  );
}