import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Select,
  Sheet,
  Stack,
  Table,
  Typography,
  Alert
} from '@mui/joy';
import CreateOrgDrawer from './CreateOrgDrawer';
import ManageFacultyTable from './ManageFacultyTable'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TouchAppIcon from '@mui/icons-material/TouchApp';

function Row({ row, getOrgs, setMessage, setIsError }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* Organization */}
      <tr>
        <td style={{ width: "10%" }}>
          <Typography level="body-md" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.id}</Typography>
        </td>
        <td style={{ width: "30%" }}>
          <Typography level="body-md" sx={{ overflow: 'scroll', textOverflow: 'ellipsis' }}>{row.name}</Typography>
        </td>
        <td style={{ width: "20%" }}>
          <Typography level="body-md" sx={{ overflow: 'scroll', textOverflow: 'ellipsis' }}>{row.org_contact}</Typography>
        </td>
        <td style={{ width: "20%" }}>
          <Typography level="body-md" sx={{ overflow: 'scroll', textOverflow: 'ellipsis' }}>{row.org_email}</Typography>
        </td>
        <td style={{ width: "20%" }}>
          <Stack direction={"row"} justifyContent={"right"} >
            <CreateOrgDrawer setMessage={setMessage} getOrgs={getOrgs} setIsError={setIsError} initState={row} />
            <Button
              aria-label="expand row"
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => {
                setOpen(!open)
              }}
              endDecorator={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
              Faculties
            </Button>
          </Stack>
        </td>
      </tr>
      <tr>
        <td style={{ height: 0, padding: 0, textAlign: 'left' }} colSpan={5}>
          {open && (
            <ManageFacultyTable org_id={row.id} open={open} setMessage={setMessage} setIsError={setIsError} />
          )}
        </td>
      </tr>
    </>
  );
}

export default function TableCollapsibleRow( { orgs, getOrgs, setMessage, setIsError }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState(orgs);
  const [sortOrder, setSortOrder] = useState({ field: 'name', direction: 'asc' });

  //This section is used for pagination
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  function labelDisplayedRows({ from, to, count }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? rows.length
      : Math.min(rows.length, (page + 1) * rowsPerPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  //This section handles sorting the table
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'rating') {
      // Convert boolean/null to numeric values for comparison
      const ratingValue = (rating) => {
        if (rating === true) return 3;
        if (rating === null || rating === undefined) return 2;
        if (rating === false) return 1;
        return 0; // for null/undefined ratings
      };
      return ratingValue(b[orderBy]) - ratingValue(a[orderBy]);
    }
    if (orderBy === 'id') {
      return b.id - a.id;  // For numeric IDs
    }
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
      return b[orderBy].localeCompare(a[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // Functionality
  useEffect(() => {
    setRows(orgs);
  }, [orgs]);

  return (
    <Sheet variant="plain">
      {/* Table Sort and Pagination */} 
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" mb={2} className="table-toolbar">
        <FormControl>
          <FormLabel>Sort by:</FormLabel>
          <Select
            value={`${sortOrder.field}-${sortOrder.direction}`}
            onChange={(_, newValue) => {
              const [field, direction] = newValue.split('-');
              setSortOrder({ field, direction });
            }}
            sx={{ minWidth: 200 }}
            variant='plain'
          >
            <Option value="name-asc">Alphabetically A-Z</Option>
            <Option value="name-desc">Alphabetically Z-A</Option>
          </Select>
        </FormControl>
        { /* Pagination Component */}
        <Stack direction="row" alignItems="center" gap={2}>
          <FormControl orientation="horizontal" size="md">
            <FormLabel>Rows/Page:</FormLabel>
            <Select onChange={handleChangeRowsPerPage} size="sm" value={rowsPerPage}>
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={25}>25</Option>
            </Select>
          </FormControl>
          <Typography level="body-sm" sx={{ fontFamily: 'UbuntuMedium'}}>
            {labelDisplayedRows({
              from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
              to: getLabelDisplayedRowsTo(),
              count: rows.length === -1 ? -1 : rows.length,
            })}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Previous Page"
              disabled={page === 0}
              onClick={() => handleChangePage(page - 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Next Page"
              disabled={
                rows.length !== -1
                  ? page >= Math.ceil(rows.length / rowsPerPage) - 1
                  : false
              }
              onClick={() => handleChangePage(page + 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Stack>
        { /* End Pagination Component */}
      </Stack>
      <Divider />
      <Alert
        variant="soft"
        color="neutral"
        startDecorator={<TouchAppIcon />}
        sx={{
          display: 'none', // Hidden by default
          '@media (max-width: 820px)': {
            marginTop:'1rem',
            display: 'flex',
            justifyContent: 'center',
          }
        }}
      >
        Scroll horizontally to view full table
      </Alert>
      <Box sx={{ 
        width: '100%', 
        overflowX: 'auto', 
        '@media (max-width: 820px)': {
          width: '100vw',
          margin: '0 -1rem',
        }}}
      >
        <Table
          hoverRow
          aria-label="collapsible organizations table"
          size="md"
          sx={{
            minWidth: '1100px', // Ensure the table has a minimum width
            '@media (max-width: 820px)': {
              width: '100%',
            }
          }}
        >
        {/* Table Head */}
        <thead>
          <tr>
            <th style={{ width: "10%" }}>
              <Typography level="body-md">ID</Typography>
            </th>
            <th style={{ width: "30%" }}>
              <Typography level="body-md">Name</Typography>
            </th>
            <th style={{ width: "20%" }}>
              <Typography level="body-md">Owner</Typography>
            </th>
            <th style={{ width: "20%" }}>
              <Typography level="body-md">Email</Typography>
            </th>
            <th style={{ width: "20%" }}>
              <Typography level="body-md" textAlign={"right"}>Actions</Typography>
            </th>
          </tr>
        </thead>
        {/* Table Body Rows */} 
          <tbody>
          {stableSort(rows, getComparator(sortOrder.direction, sortOrder.field))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => (
              <Row key={row.id} row={row} getOrgs={getOrgs} setMessage={setMessage} setIsError={setIsError} />
            ))}
            {emptyRows > 0 && (
              <tr
                style={{
                  height: `calc(${emptyRows} * 40px)`,
                  "--TableRow-hoverBackground": "transparent",
                }}
              >
                <td colSpan={4} aria-hidden />
              </tr>
            )}
          </tbody>
        </Table>
      </Box>
    </Sheet>
  );
}