// Updated slugify function based on the provided Python implementation
export const slugify = (text) => {
  // Convert to lowercase
  let slug = text.toLowerCase();
  // Normalize Unicode characters
  // This converts characters with diacritics to their basic Latin equivalents
  slug = slug.normalize('NFKD') 
  // Remove the combining diacritical marks
  slug = slug.replace(/[\u0300-\u036f]/g, '')
  // Replace spaces with hyphens
  slug = slug.replace(/\s/g, '-');
  // Remove any characters that are not alphanumeric, hyphens, underscores, or forward slashes
  slug = slug.replace(/[^\w\-_/]/g, '');
  // Remove leading and trailing hyphens
  slug = slug.replace(/^-+|-+$/g, '');
  // Replace multiple consecutive hyphens with a single hyphen
  slug = slug.replace(/-+/g, '-');
  // Remove leading and trailing forward slashes
  slug = slug.replace(/^\/+|\/+$/g, '');
  return slug;
};

// Test cases
/*console.log(slugify('Hello World!')); // hello-world
console.log(slugify('São Paulo')); // sao-paulo
console.log(slugify('münchen')); // munchen
console.log(slugify('La Niña')); // la-nina
console.log(slugify('über/étagère')); // uber/etagere
console.log(slugify('C++/C# Programming')); // c/c-programming
console.log(slugify('   spaces   ')); // spaces
console.log(slugify('///path/to/resource///')); // path/to/resource*/
