import React, { useRef, useState } from "react";
import axios from 'axios';
import {
  Alert,
  Box,
  Button,
  Link,
  Typography,
} from '@mui/joy';
import './DragDrop.css';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DuplicateFilesModal from './DuplicateFileModal';
import ImportFilesModal from './ImportFilesModal';
import CreateNoteModal from './CreateNoteModal';

export default function DragAndDrop({ setFiles, refresh, fileTitle, disabled, disabledMessage, subscriptionTier, remainingUploads = 10 }) {
  const fileInputRef = useRef(null);
  const [allDuplicates, setAllDuplicates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);
  const [error, setError] = useState('');

  const validateFileCount = (fileList) => {
    if (subscriptionTier === 1 && fileList.length > remainingUploads) {
      setError(`You only have ${remainingUploads} file upload${remainingUploads === 1 ? '' : 's'} remaining in your free trial`);
      return false;
    }
    setError('');
    return true;
  };

  const handleButtonClick = () => {
    if (disabled) return;
    fileInputRef.current.click();
  };

  const handleImportClick = () => {
    setImportModalOpen(true);
  };

  const handleCreateNoteClick = () => {
    setCreateNoteModalOpen(true);
  };

  const handleFileSelect = (event) => {
    if (!validateFileCount(event.target.files)) {
      event.target.value = '';
      return;
    }
    handleFileChange(event.target.files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (disabled) return;

    if (!validateFileCount(event.dataTransfer.files)) {
      return;
    }
    handleFileChange(event.dataTransfer.files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    if (disabled) {
        event.dataTransfer.dropEffect = 'none';
    }
  };

  const removeFileExtension = (title) => {
    // Split the title into parts based on dots
    const parts = title.split('.');
    
    // If there's only one part (no dots) or the last part isn't a common file extension, return the original title
    if (parts.length === 1 || !isCommonFileExtension(parts[parts.length - 1])) {
      return title;
    }
    
    // Remove the last part (the extension) and join the rest
    return parts.slice(0, -1).join('.');
  };

  // Helper function to check if a string is a common file extension
  const isCommonFileExtension = (ext) => {
    const commonExtensions = ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv', 'jpg', 'jpeg', 'png', 'gif', 'mp3', 'mp4', 'zip', 'rar'];
    return commonExtensions.includes(ext.toLowerCase());
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const uploadFiles = async (files) => {
    const formData = new FormData();
    const response = await axios.get('/api/course_files_retrieve');
    let duplicatedFiles = [];

    files.forEach((fileObj) => {
      // Check if the file already exists and has a 'success' or 'Complete' status
      const existingFile = response.data.find(file => 
        removeFileExtension(file.title).toLowerCase() === removeFileExtension(fileObj.file.name).toLowerCase()
      );
      
      if (!existingFile || (existingFile.status !== 'success' && existingFile.status !== 'Complete')) {
        formData.append('file', fileObj.file);
        formData.append('isSyllabus', 'false');
      } else {
        duplicatedFiles.push(fileObj.file.name);
      }
    });

    if (duplicatedFiles.length > 0) {
      setModalOpen(true);
      setAllDuplicates(duplicatedFiles);
      setFiles((prevFiles) => prevFiles.filter((file) => !duplicatedFiles.includes(file.title)));
      if (files.length === duplicatedFiles.length) {
        refresh();
        return;
      }
    }

    try {
      const uploadResponse = await axios.post('/api/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (uploadResponse.status === 200) {
        uploadResponse.data.results.forEach(result => {
          const status = result.status === 'Failed to upload to S3' ? 'Error' : 'Processing';
          updateFileStatus(result.file_name, status);
        });
      } else {
        files.forEach(fileObj => updateFileStatus(fileObj.title, 'Error'));
      }
    } catch (error) {
      //console.error('Upload error:', error);
      files.forEach(fileObj => updateFileStatus(fileObj.title, 'Error'));
    } finally {
      refresh();
    }
  };

  const handleFileChange = (fileList) => {
    const newFiles = Array.from(fileList).map((file) => ({
      id: Date.now() + Math.random(),
      title: file.name,
      displayTitle: removeFileExtension(file.name),
      isactive: true,
      status: 'Loading',
      file,
    }));

    setFiles((prevFiles) => [...newFiles, ...prevFiles]);
    if (newFiles.length > 0) {
      uploadFiles(newFiles);
    }
  };

  const updateFileStatus = (fileName, status) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.title === fileName ? { ...file, status } : file
      )
    );
  };

  if (disabled) {
        return (
            <Box 
                component="section" 
                className="drag-drop-container disabled"
            >
              <Typography level="body-lg">{disabledMessage}</Typography>
              <Button 
                  size="md" 
                  variant='soft' 
                  color='primary'
                  component={Link}
                  href="/subscriptions?upgrade-trial=true"
              >
                  Select a Plan
              </Button>

            </Box>
        );
    }

  return (
    <Box component="section" className="drag-drop-container" onDrop={handleDrop} onDragOver={handleDragOver}>
      <Typography level="body-lg">Drag and drop your files here </Typography>
      <Typography level="body-lg">or</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
        <Button variant='soft' color='primary' onClick={handleButtonClick}>Select Files</Button>
        <Button variant="soft" color="neutral" onClick={handleImportClick}>Import Files</Button>
        <Button variant="soft" color="neutral" onClick={handleCreateNoteClick}>Create Note</Button>
      </Box>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
        multiple
      />
      <Typography level="component-sm">Supported filetypes: .pdf, .pptx, .docx, .txt, .tex, .md, .html, .htm, .mp3, .mp4, .wav, .ogg, .m4a</Typography>
      {error && (
        <Alert 
          variant="soft" 
          color="danger" 
          size="md" 
          startDecorator={<ErrorOutlineIcon />}
          role="alert"
          aria-live="polite"
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      )}
      <DuplicateFilesModal modalOpen={modalOpen} closeModal={closeModal} duplicatedFiles={allDuplicates}/>
      <ImportFilesModal 
        open={importModalOpen}
        onClose={() => setImportModalOpen(false)}
        onImport={refresh}
      />
      <CreateNoteModal 
        open={createNoteModalOpen}
        onClose={() => setCreateNoteModalOpen(false)}
        refresh={refresh}
      />
    </Box>
  );
}