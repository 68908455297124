import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Grid,
  Link,
  Typography,
} from '@mui/joy';
import Container from '../components/Container';
import ManageOrgTable from '../components/OrgManagement/ManageOrgTable';
import Logo from '../assets/logo.svg';
import LogoMobile from '../assets/logoMobile.svg';
import CreateOrgDrawer from '../components/OrgManagement/CreateOrgDrawer';

export default function CreateOrg() {
  const [orgs, setOrgs] = useState([]);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    getOrgs();
  }, []);

  const getOrgs = async () => {
    try {
        const response = await axios.get('/api/organizations/all');

        if (response.data.length === 0) {
          setOrgs([]);
          return;
        }
        
        const processedData = response.data.map((item) => ({
          id: item.org_id,
          name: item.org_name,
          org_contact: item.org_contact,
          org_email: item.org_email,
      }));

      setOrgs(processedData);
    } catch (error) {
      console.error('Error fetching question logs:', error);
    }
  };

  return (
    <Box>
      <header className="header-wrapper">
        <Box className="left-wrapper">
            <a href="/login/admin"><img src={Logo} alt="All Day TA" className="logo" /></a>
            <a href="/login/admin"><img src={LogoMobile} alt="All Day TA" className="logo-mobile" /></a>
        </Box>
      </header>
      <Container container spacing={2}>
        <Grid container spacing={2}>
            <Grid xs={12}>
              <Typography level="h1">Manage Organizations</Typography>
            </Grid>
            <Grid xs={12}>
              <Typography level="title-sm">Already created an organization?&nbsp;
              <Link href="/signup/admin">
                Create an Admin instead
              </Link>
              </Typography>
            </Grid>
            <Grid xs={12}>
              <CreateOrgDrawer setMessage={setMessage} setIsError={setIsError} />
            </Grid>
            <Grid xs={12}>
              {message && (
                  <Alert
                      color={isError ? 'danger' : 'success'} 
                      sx={{ mb: 2 }}
                      role="alert"
                      aria-live="polite"
                      >
                      {message}
                  </Alert>
              )}
            </Grid>
            <Grid xs={12}>
              <ManageOrgTable orgs={orgs} getOrgs={getOrgs} setMessage={setMessage} setIsError={setIsError} />
            </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
