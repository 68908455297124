import { useMemo } from 'react';

export const useWeekUtils = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  // Get week number for a given date
  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // Get date range for a given week number
  const getWeekRange = (weekNum) => {
    const firstDayOfYear = new Date(currentYear, 0, 1);
    
    // Adjust first day to previous Sunday if necessary
    const dayOffset = firstDayOfYear.getDay();
    const firstWeekStart = new Date(firstDayOfYear);
    firstWeekStart.setDate(firstWeekStart.getDate() - dayOffset);
    
    // Calculate start date for the given week
    const weekStart = new Date(firstWeekStart);
    weekStart.setDate(weekStart.getDate() + (weekNum - 1) * 7);
    
    // Calculate end date (6 days after start)
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 6);
    
    return { start: weekStart, end: weekEnd };
  };

  // Format week label with date range
  const formatWeekLabel = (weekNum) => {
    const { start, end } = getWeekRange(weekNum);
    const formatDate = (date) => {
      const month = date.toLocaleString('default', { month: 'short' });
      const day = date.getDate();
      return `${month} ${day}`;
    };
    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  // Generate all weeks for current year
  const getAllWeeks = () => {
    const totalWeeks = 53; // Maximum possible weeks in a year
    return Array.from({ length: totalWeeks }, (_, i) => ({
      weekNum: i + 1,
      dateRange: formatWeekLabel(i + 1)
    }));
  };

  return {
    currentYear,
    getWeekNumber,
    getWeekRange,
    formatWeekLabel,
    getAllWeeks
  };
};
