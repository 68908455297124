import { 
  Card, 
  Typography,
  Skeleton
} from '@mui/joy';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';

const WeeklyChart = ({ distribution, formatWeekLabel, isLoading }) => {
  const chartData = Object.entries(distribution)
    .sort(([weekA], [weekB]) => parseInt(weekA) - parseInt(weekB))
    .map(([week, count]) => {
      const weekNum = parseInt(week);
      return {
        id: `week-${weekNum}`,
        week: weekNum,
        count: count,
        dateRange: formatWeekLabel(weekNum)
      };
    });

  return (
    <Card variant="outlined" sx={{ mb: 4, height: 400, p: 2 }}>
      <Typography level="h2">
        Questions per Week ({new Date().getFullYear()})
      </Typography>
      <Typography level="component-md" sx={{ mb: 2 }}>
        Changing the week filter does not impact this chart
      </Typography>
      {isLoading ? (
        <Skeleton 
          variant="rectangular" 
          width="100%" 
          height="100%"
          animation="wave"
          sx={{ 
            borderRadius: 'sm',
          }}
        />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart 
            data={chartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 45 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="dateRange"
              interval={4}
              angle={-45}
              textAnchor="end"
              height={60}
              tick={{ fontSize: 12 }}
            />
            <YAxis />
            <RechartsTooltip 
              formatter={(value, name) => [`${value} questions`, 'Volume']}
              labelFormatter={(dateRange) => dateRange}
              key={`tooltip-${new Date().getFullYear()}`}
            />
            <Line 
              type="monotone" 
              dataKey="count" 
              stroke="#8884d8" 
              strokeWidth={2}
              dot={false}
              key={`line-${new Date().getFullYear()}`}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default WeeklyChart;

