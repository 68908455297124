import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import { CssVarsProvider } from '@mui/joy/styles';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import theme from './theme/theme';
import CourseInfo from './pages/CourseInfo';
import Files from './pages/Files';
import ForgotPassword from './pages/ForgotPassword';
import Settings from './pages/Settings';
import Signup from './pages/Signup';
import Summaries from './pages/Summaries';
import Header from './components/Header';
import TrialBanner from './components/TrialBanner';
import ProfessorLogin from './pages/ProfessorLogin';
import ProfessorSignUp from './pages/ProfessorSignUp';
import AdminAnalytics from './pages/AdminAnalytics';
import ResetPassword from './pages/ResetPassword';
import Chatbot from './components/Chatbot'; // change this later, to a page?
import CreateOrg from './pages/CreateOrg';
import CreateCourse from './pages/CreateCourse'
import Unauthorized from './pages/Unauthorized'
import Subscriptions from './pages/Subscriptions'
import YourSubscription from './pages/YourSubscription'
import CreateSubscription from './pages/CreateSubscription';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './routes/ProtectedRoute';
import AdminProtectedRoute from './routes/ADTAProtectedRoute'
import StudentChatbot from './components/StudentChatbot';
import { SubscriptionProvider, useSubscription } from './context/SubscriptionContext';


function App() {
    const headerRef = useRef();
    const chatbotRef = useRef();
    const location = useLocation();
    const studentChatbotRef = useRef(null);
    const pathsWithoutHeader = ['/login/admin', '/sign-up', '/forgot-password', '/reset-password', '/create-course', '/subscriptions', '/signup/admin'];
    const [isLocalhost, setIsLocalhost] = useState(false);

    const handleCourseChange = () => {
        if (headerRef.current) {
            headerRef.current.refreshCourseList();
        }
    };

    const setOnCourseChange = (callback) => {
        if (headerRef.current) {
            headerRef.current.setOnCourseChange(callback);
        }
    };

    const isStudentChatbotRoute = () => {
        const specificRoutes = [
            '/preview', '/signup/admin', '/course-info', 
            '/create-course', '/files', '/', '/forgot-password', 
            '/settings', '/sign-up', '/subscriptions', '/your-subscription', '/summaries', '/login/admin', '/reset-password'
        ];
        return !specificRoutes.some(route => location.pathname.toLowerCase() === route.toLowerCase());
    };

    const shouldHideHeader = pathsWithoutHeader.includes(location.pathname)  || isStudentChatbotRoute();

    const getBaseUrl = () => {
        const origin = window.location.origin;
        if (origin.includes('localhost') || origin.includes('127.0.0.1')) {
            return 'localhost';
        } else if (origin.includes('staging.alldayta.com')) {
            return 'https://staging.alldayta.com';
        } else {
            return 'https://app.alldayta.com';  
        }
    };

    useEffect(() => {
        const baseUrl = getBaseUrl();
        setIsLocalhost(baseUrl === 'localhost');
    }, []);

     const ProtectedContent = () => {
        const { subscription } = useSubscription(); // Move it here where context is available
        
        return (
            <>
                {!shouldHideHeader && (
                    <>
                        {subscription?.tier_id === 1 && <TrialBanner />}
                        <Header onCourseChange={handleCourseChange} ref={headerRef} chatbotRef={chatbotRef} />
                    </>
                )}
                <Outlet />
            </>
        );
    };

    return (
        <AuthProvider>
            <CssVarsProvider theme={theme}>
                <div className="App" style={{
                    backgroundColor: 'var(--joy-palette-background-body)',
                    minHeight: '100vh'}}>

                    <Routes>
                        {/* Public routes - no subscription check required */}
                        <Route path='/login/admin' element={<ProfessorLogin />} />
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                        <Route path='/reset-password' element={<ResetPassword />} />
                        <Route path='/unauthorized' element={<Unauthorized />} />
                        <Route path='/sign-up' element={<Signup />} />

                        {/* Protected routes with subscription */}
                        <Route element={
                            <SubscriptionProvider>
                                <ProtectedRoute>
                                        <ProtectedContent />
                                </ProtectedRoute>
                            </SubscriptionProvider>
                        }>
                            <Route path="/preview" element={<Chatbot ref={chatbotRef} />} />
                            <Route path='/course-info' element={<CourseInfo headerRef={headerRef} />} />
                            <Route path='/create-course' element={<CreateCourse headerRef={headerRef}/>} />
                            <Route path='/files' element={<Files headerRef={headerRef} />} />
                            <Route path='/' element={<Files headerRef={headerRef} />} />
                            <Route path='/settings' element={<Settings headerRef={headerRef} />}/>
                            <Route path='/subscriptions' element={<Subscriptions/>} />
                            <Route path='/summaries' element={<Summaries setOnCourseChange={setOnCourseChange}/>} />
                            <Route path='/your-subscription' element={<YourSubscription headerRef={headerRef}/>} />
                        </Route>

                        {/* Super admin routes - ADTA Only - Subscription Check Should be removed later */}
                        <Route element={ 
                            isLocalhost ?
                            <SubscriptionProvider> 
                                <ProtectedContent />
                            </SubscriptionProvider> :
                            <SubscriptionProvider>
                                <AdminProtectedRoute requiredOrgId={1}>
                                    <ProtectedContent />
                                </AdminProtectedRoute>
                            </SubscriptionProvider>
                        }>
                            <Route path='/create-sub' element={<CreateSubscription />} />
                            <Route path='/create-org' element={<CreateOrg />} />
                            <Route path='/signup/admin' element={<ProfessorSignUp />} />
                            <Route path='/analytics' element={<AdminAnalytics />} />
                        </Route>

                        {/* Student chatbot route */}
                        <Route path="*" element={
                            <SubscriptionProvider>
                                    <StudentChatbot 
                                        isStudentChatbot={true} 
                                        ref={studentChatbotRef} 
                                    />
                            </SubscriptionProvider>
                        } />
                    </Routes>
                </div>
            </CssVarsProvider>
        </AuthProvider>
    );
}

export default App;