import { useEffect, useState } from 'react';
import {
  Sheet,
  Table,
  Typography,
} from '@mui/joy';
import axios from 'axios';
import CreateDepartmentDrawer from './CreateDepartmentDrawer';

function Row({ row, org_id, faculty_id, getDepartments, setMessage, setIsError }) {
  return (
    <>
      {/* Department */}
      <tr>
        <td style={{ width: "10%" }}>
          <Typography level="body-md" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.id}</Typography>
        </td>
        <td style={{ width: "30%" }}>
          <Typography level="body-md" sx={{ overflow: 'scroll', textOverflow: 'ellipsis' }}>{row.name}</Typography>
        </td>
        <td style={{ width: "20%" }}>
          <Typography level="body-md" sx={{ overflow: 'scroll', textOverflow: 'ellipsis' }}>{row.owner_name}</Typography>
        </td>
        <td style={{ width: "20%" }}>
          <Typography level="body-md" sx={{ overflow: 'scroll', textOverflow: 'ellipsis' }}>{row.owner_email}</Typography>
        </td>
        <td style={{ width: "20%", textAlign: "right"}}>
          <CreateDepartmentDrawer org_id={org_id} faculty_id={faculty_id} getDepartments={getDepartments} setMessage={setMessage} setIsError={setIsError} initState={row} />
        </td>
      </tr>
    </>
  );
}

export default function TableCollapsibleRow({ org_id, faculty_id, open, setMessage, setIsError }) {
  const [departments, setDepartments] = useState([]);

  const getDepartments = async (faculty_id) => {
    try {
        const response = await axios.get(`/api/department?faculty_id=${faculty_id}`);

        if (response.data.departments.length === 0) {
          setDepartments([]);
          return;
        }
        
        const processedData = response.data.departments.map((item) => ({
          id: item.id,
          faculty_id: item.faculty_id,
          name: item.name,
          owner_id: item.owner_id,
          owner_name: item.owner_name,  
          owner_email: item.owner_email, 
        })).sort((a, b) => a.name.localeCompare(b.name)); // alphabetical sort;

      setDepartments(processedData);
    } catch (error) {
      console.error('Error fetching question logs:', error);
    }
  };

  useEffect(() => {
    if(open) getDepartments(faculty_id);
  }, [open, faculty_id]);

  return (
    <Sheet
      variant="plain"
      sx={{ pl: 2, pb: 1, backgroundColor: 'var(--joy-palette-neutral-200)', wordWrap:'break-word', }}
    >
      <Table
        hoverRow
        aria-label="Departments table"
        size="md"
      >
      {/* Table Body Rows */} 
        <tbody>
          {departments.map((row) => (
            <Row key={row.id} row={row} org_id={org_id} faculty_id={faculty_id} getDepartments={getDepartments} setMessage={setMessage} setIsError={setIsError} />
          ))}
        </tbody>
      </Table>
      <CreateDepartmentDrawer org_id={org_id} faculty_id={faculty_id} getDepartments={getDepartments} setMessage={setMessage} setIsError={setIsError} />
    </Sheet>
  );
}