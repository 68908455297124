import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Card,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Typography
} from '@mui/joy';
import Container from '../components/Container';
import QuestionTable from '../components/QuestionTable';
import { SummaryCardSkeleton, QuestionTableSkeleton } from '../components/LoadingSkeletons';
import CourseCheck from '../components/CourseCheck';
import './Summaries.css';

export default function Summaries( { setOnCourseChange } ) {
  const [selectedWeek, setSelectedWeek] = useState("");
  const [weeks, setWeeks] = useState([]);
  const [questionAnswerInWeek, setQuestionAnswerInWeek] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    document.title = "Weekly Summaries - All Day TA";
  }, []);

  function getStartAndEndTimes(weekString) {
    const [dateRange, year] = weekString.split(', ');
    const [startDate, endDate] = dateRange.split(' - ');
    
    // Handle year transition
    let startYear = parseInt(year);
    let endYear = startYear;
    
    // If start month is December and end month is January, the start date should be in the previous year
    if (startDate.includes('Dec') && endDate.includes('Jan')) {
        startYear = startYear - 1;
    }
    
    const start = new Date(`${startDate} ${startYear}`);
    const end = new Date(`${endDate} ${endYear}`);
    
    const startTime = `${start.getFullYear()}-${String(start.getMonth() + 1).padStart(2, '0')}-${String(start.getDate()).padStart(2, '0')} 00:00:00.000000+00`;
    const endTime = `${end.getFullYear()}-${String(end.getMonth() + 1).padStart(2, '0')}-${String(end.getDate()).padStart(2, '0')} 23:59:59.999999+00`;
    
    return { startTime, endTime };
  }

  const getQuestionLogs = async (startDate, endDate) => {
    try {
        const params = {
            start_time: startDate, 
            end_time: endDate,
        };
        //console.log('Requesting question logs with params:', params);
        const response = await axios.get('/api/get_question_log', { params });
        //console.log('Response data:', response.data);

        if (response.data.results.length === 0) {
          //console.log('No question logs found for the given date range');
          setQuestionAnswerInWeek([]);
          setQuestionCount(0);
          return;
        }

        const processedData = response.data.results.map((item) => ({
          id: item.question_id,
          question: item.original_question,
          flagged: item.flag_unanswered,
          answer: item.answer.replace(/\[\d+\]\s*/g, ''),
          rating: item.response_rating,
          issyllabus: item.issyllabus,
          note_id: item.note_id
      }));
      //console.log('Processed data:', processedData);

      setQuestionAnswerInWeek(processedData);
      setQuestionCount(processedData.length)
    } catch (error) {
        //console.error('Error fetching question logs:', error);
    }
  };

  const fetchSummary = async (startDate, endDate) => {
    try {
        const response = await axios.get('/api/summary', {
            params: {
                start_date: startDate,
                end_date: endDate,
            },
        });
        setSummary(response.data.summary);
    } catch (error) {
        //console.error('Error fetching summary:', error);
        setSummary('');
    }
  };

  const fetchWeeks = async () => {
    try {
        setIsLoading(true);
        const response = await axios.get('/api/weeks');
        //console.log(response.data.weeks);
        
        if (response.data.weeks.length === 0) {
          setError("No weeks found. Please check back later.");
          setWeeks([]);
          setSelectedWeek("");
          setShowAlert(true);
        } else {
          const parsedWeeks = response.data.weeks.map(week => {
            const [dateRange, year] = week.split(', ');
            const [startDate, endDate] = dateRange.split(' - ');
            const startDateObj = new Date(`${startDate} ${year}`);
            const endDateObj = new Date(`${endDate} ${year}`);

            // If end date is in January and start date is in December, adjust the start date year
            if (startDate.includes('Dec') && endDate.includes('Jan')) {
              startDateObj.setFullYear(startDateObj.getFullYear() - 1);
            }

            return {
              originalString: week,
              date: endDateObj
            };
          });

        const sortedWeeks = parsedWeeks.sort((a, b) => b.date - a.date);

          setWeeks(sortedWeeks.map(week => week.originalString));
          setSelectedWeek(sortedWeeks[0].originalString);
          setShowAlert(false);
        }
        setError(null);
      } catch (error) {
          //console.error('Error fetching weeks:', error);
          setError("An error occurred while fetching weeks. Please try again later.");
          setShowAlert(true);
      } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (selectedWeek) {
      //console.log("selected week", selectedWeek);
      setIsLoading(true);
      const { startTime, endTime } = getStartAndEndTimes(selectedWeek);
      getQuestionLogs(startTime, endTime).finally(() => setIsLoading(false));
      const startDateTrimmed = startTime.split(' ')[0];
      const endDateTrimmed = endTime.split(' ')[0];
      fetchSummary(startDateTrimmed, endDateTrimmed);
    }  
  }, [selectedWeek]);

  useEffect(() => {
      fetchWeeks();
  }, []);

  useEffect(() => {
    if (setOnCourseChange) {
        setOnCourseChange(() => {
            fetchWeeks();
            if (selectedWeek) {
              setIsLoading(true);
              const { startTime, endTime } = getStartAndEndTimes(selectedWeek);
              getQuestionLogs(startTime, endTime).finally(() => setIsLoading(false));
              const startDateTrimmed = startTime.split(' ')[0];
              const endDateTrimmed = endTime.split(' ')[0];
              fetchSummary(startDateTrimmed, endDateTrimmed);
            }
        });
    }
  }, [setOnCourseChange, selectedWeek]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography level="h1" mb={3}>Weekly Summaries</Typography>
        </Grid>
        <CourseCheck>
        <Grid xs={12}>
          {showAlert && error && (
            <Alert
              variant="soft"
              color="warning"
              sx={{ mt: 2 }}
              role={showAlert ? "alert" : "status"} 
              aria-live={showAlert ? "polite" : "off"}
              >
              {error}
            </Alert>
          )}
          {showAlert && weeks.length === 0 && !error && (
            <Alert
              variant="soft"
              color="neutral"
              sx={{ mt: 2 }}
              role={showAlert ? "alert" : "status"} 
              aria-live={showAlert ? "polite" : "off"}
            >
              No summaries are currently available. Please check back later.
            </Alert>
          )}
          {!showAlert && (
          <FormControl>
              <FormLabel>Select Week</FormLabel>
              <Select
                variant='outlined'
                color='primary'
                value={selectedWeek}
                onChange={(_, newValue) => setSelectedWeek(newValue)}
                sx={{ width: '13.75rem' }}
                >
                {weeks.map((week) => (
                  <Option key={week} value={week}>
                  {week}
                  </Option>
                ))}
              </Select>
          </FormControl>
          )}
        </Grid>
        {isLoading ? (
          <SummaryCardSkeleton />
        ) : (
          <Card variant='soft' sx={{ mb: 3, mt: 3, padding: '2.2rem' }}>
            <Grid container>
              <Grid xs={12} sm={2}>
                  <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      <Typography sx={{ fontSize: '2.25rem', fontWeight: '700' }} mr={2}>
                      {questionCount}
                      </Typography>
                      {/* TODO: Update to switch depending on whether the count is higher or lower than last week 
                      <ArrowUpwardIcon/>*/}
                      </Box>
                <Typography level="body-sm" sx={{ fontFamily: 'componentslt', color: 'var(--joy-palette-primary-700)' }}>Questions Asked</Typography>
              </Grid>
              <Grid xs={12} sm={10}  className="card-separator">
                <Typography level="h2" sx={{ fontSize: '1.5rem'}} mb={2}>
                  Summary
                </Typography>
                <Typography level="body-md">
                  {summary}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        )}
        <Grid xs={12} sx={{ textAlign: 'left' }}>
          <Typography level="h2" sx={{ fontSize: '1.5rem'}} mb={3}>
            Questions
          </Typography>
          {isLoading ? (
            <QuestionTableSkeleton />
          ) : (
            <>
            <QuestionTable questionAndAnswers={questionAnswerInWeek}/>
            </>
          )}
        </Grid>
        </CourseCheck>
      </Grid>
    </Container>
  );
}

