import { 
  Card, 
  Grid,
  Stack, 
  Typography,
  Skeleton
} from '@mui/joy';

const MetricCard = ({ title, value, percentage, isLoading }) => {
  return (
    <Card variant="soft">
      <Typography level="h3">{title}</Typography>
      {isLoading ? (
        <Skeleton 
          variant="text"
          width="100%"
          height={48}
          sx={{
            borderRadius: 'sm',
          }}
        />
      ) : percentage !== undefined ? (
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography level="h2">{value}</Typography>
          <Typography level="body-md" sx={{ color: 'neutral.500' }}>
            {percentage}%
          </Typography>
        </Stack>
      ) : (
        <Typography level="h2">{value}</Typography>
      )}
    </Card>
  );
};

const MetricsOverview = ({ metrics, isLoading }) => {
  const metricConfigs = [
    {
      id: 'total',
      title: 'Total Questions',
      value: metrics?.total || 0
    },
    {
      id: 'syllabus',
      title: 'Syllabus Questions',
      value: metrics?.syllabusQuestions || 0,
      percentage: metrics ? ((metrics.syllabusQuestions / metrics.total) * 100).toFixed(1) : 0
    },
    {
      id: 'followups',
      title: 'Follow-ups',
      value: metrics?.followUps || 0,
      percentage: metrics ? ((metrics.followUps / metrics.total) * 100).toFixed(1) : 0
    },
    {
      id: 'unique-ips',
      title: 'Unique IPs',
      value: metrics?.uniqueIPs || 0
    }
  ];

  return (
    <Grid container spacing={2} sx={{ mb: 4 }}>
      {metricConfigs.map((metric) => (
        <Grid key={metric.id} xs={12} md={3}>
          <MetricCard {...metric} isLoading={isLoading} />
        </Grid>
      ))}
    </Grid>
  );
};

export default MetricsOverview;
