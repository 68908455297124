import React from 'react';
import { 
  Box,
  Card, 
  Chip, 
  Grid, 
  Stack, 
  Typography,
  Skeleton
} from '@mui/joy';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import LatexRenderer from '../LatexRenderer';

const QuestionCard = React.memo(({ question, isLoading }) => {
  if (isLoading) {
    return (
      <Card variant="soft" sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box sx={{ display: 'flex', gap: 1, mb: 1, flexWrap: 'wrap' }}>
                <Skeleton variant="text" width={120} height={30} />
                <Skeleton variant="text" width={80} height={30} />
                <Skeleton variant="text" width={100} height={30} />
                <Skeleton variant="text" width={90} height={30} />
              </Box>
              <Skeleton variant="text" width={150} height={24} />
            </Stack>
          </Grid>
          <Grid xs={12} md={6}>
            <Skeleton variant="text" width={100} height={24} sx={{ mb: 1 }} />
            <Skeleton variant="text" width="90%" height={20} />
            <Skeleton variant="text" width="75%" height={20} />
          </Grid>
          <Grid xs={12} md={6}>
            <Skeleton variant="text" width={100} height={24} sx={{ mb: 1 }} />
            <Skeleton variant="text" width="85%" height={20} />
            <Skeleton variant="text" width="80%" height={20} />
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Card variant="soft" sx={{ mb: 2 }}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'flex', gap: 1, mb: 1, flexWrap: 'wrap' }}>
              <Typography level="title-md">{question.course_name}</Typography>
              <Chip 
                size="md" 
                startDecorator={<LanguageRoundedIcon />}
              >
                {question.language}
              </Chip>
              {question.is_syllabus && (
                <Chip 
                  size="md" 
                  color="primary" 
                  startDecorator={<MenuBookRoundedIcon />}
                >
                  Syllabus Question
                </Chip>
              )}
              {question.response_rating !== null && (
                <Chip 
                  size="md" 
                  color={question.response_rating ? "success" : "danger"}
                  startDecorator={question.response_rating ? 
                    <CheckRoundedIcon /> : 
                    <CloseRoundedIcon />
                  }
                >
                  {question.response_rating ? "Helpful" : "Not Helpful"}
                </Chip>
              )}
              {question.is_follow_up && (
                <Chip 
                  size="md" 
                  color="success" 
                  startDecorator={<ForumRoundedIcon />}
                >
                  Follow-up
                </Chip>
              )}
              <Chip 
                size="md" 
                startDecorator={<SmartToyRoundedIcon />}
              >
                {question.llm_used}
              </Chip>
              <Chip size="md">
                Similarity: {(question.similarity_score * 100).toFixed(1)}%
              </Chip>
            </Box>
            <Typography level="body-sm" sx={{ mb: 1 }}>
              {new Date(question.timestamp).toLocaleString()}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} md={6}>
          <Typography level="title-md" sx={{ mb: 1 }}>Question:</Typography>
          <Typography level="body-md">{question.question}</Typography>
        </Grid>
        <Grid xs={12} md={6}>
          <Typography level="title-md" sx={{ mb: 1 }}>Answer:</Typography>
          <LatexRenderer text={question.answer} />
        </Grid>
      </Grid>
    </Card>
  );
});

QuestionCard.displayName = 'QuestionCard';
export default QuestionCard;
