import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  Checkbox,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListDivider,
  Stack,
  Typography,
} from '@mui/joy';
import { getTierDetails } from '../config/subscriptionConfig';
import OpenInNew from '@mui/icons-material/OpenInNew';

const SubscriptionCard = ({ 
  tierId, 
  isActive = false, 
  onSubscribe, 
  onCancel,
  customButton,
  daysLeft,
  isTrialExpired,
  isSubscriptionScreen = false
}) => {
  const tierDetails = getTierDetails(tierId);

  if (!tierDetails) return null;

  const isFreeTrial = tierId === 1;
  const isInstitutional = tierId === 5;

  const renderActionButton = () => {
    // Free trial (tier 1) - never show button
    if (isFreeTrial) {
      return null;
    }

    // Institutional or department licenses - show demo button on Select Subscription Screen
    if (isInstitutional) {
      if (isSubscriptionScreen) {
        return null;
      }
      return (
        <>
        <CardActions>
          <Button
            variant="solid"
            color="primary"
            component="a" 
            href="https://alldayta.com/book-a-demo"
            target="_blank"
            rel="noopener" 
            endDecorator={<OpenInNew />}
          >
            Schedule a Demo
          </Button>
        </CardActions>
        </>
      );
    }

    // Active subscription - show manage subscription button
    if (isActive) {
      return (
        <>
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            component="a" 
            href="https://billing.stripe.com/p/login/14kcPxaSO6k94nu8ww"
            target="_blank"
            rel="noopener" 
            endDecorator={<OpenInNew />}
          >
            Manage
          </Button>
        </CardActions>
        </>
      );
    }

    // Regular subscription cards - show subscribe button
    return (
      <>
        <CardActions>
          <Button
            variant="soft"
            color="primary"
            onClick={() => onSubscribe?.(tierId)}
          >
            Select
          </Button>
        </CardActions>
      </>
    );
  };

  const renderPrice = () => {
    if (isInstitutional) {
      return null;
    }

    return (
      <Stack direction="row" alignItems="baseline" gap={1}>
        {tierDetails.fullPrice && tierDetails.fullPrice !== tierDetails.price && (
          <Typography 
            level="body-md" 
            sx={{ 
              textDecoration: 'line-through',
            }}
          >
            ${tierDetails.fullPrice}
          </Typography>
        )}
        <Typography level="h4">
          {tierDetails.price === 0 ? 'Free' : `$${tierDetails.price}`} {tierDetails.currency}
        </Typography>
        <Typography level="body-md">{tierDetails.billingPeriod}</Typography>
      </Stack>
    );
  };

  return (
      <Card variant="outlined" size="lg">
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography level="h3">{tierDetails.name}</Typography>
          {isActive && (
            <>
              <Chip color="success">Active</Chip>
              {isFreeTrial && (
                <Chip color={isTrialExpired ? "danger" : "warning"}>
                  {isTrialExpired ? 'Trial Expired' : `${daysLeft} days left`}
                </Chip>
              )}
            </>
          )}
        </Stack>
        {renderPrice()}
        <Divider inset="none"/>
          <List size="md" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
            {tierDetails.features.map((feature, index) => (
              <ListItem key={index}>
                {feature}
              </ListItem>
            ))}
          </List>
        {renderActionButton()}
      </Card>
  );
}

export default SubscriptionCard;

