import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Select,
  Sheet,
  Stack,
  Table,
  Typography,
  Snackbar,
} from '@mui/joy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import LatexRenderer from './LatexRenderer';
import CreateNoteModal from './CreateNoteModal';
import './Tables.css';


function Row(props) {
  const { row, handleCreateNoteClick } = props;
  const [open, setOpen] = useState(props.initialOpen || false);

  // Function to get appropriate label for rating
  const getRatingLabel = (rating) => {
    if (rating === true) return "Rated positively";
    if (rating === false) return "Rated negatively";
    return "Not rated";
  };

  return (
    <>
      <tr className="question-row">
        <td className="question-column">
            <Stack
              direction={{xs: 'column', sm: 'row'}}
              alignItems="flex-start"
              spacing={1}
              >
              {row.issyllabus && (
                <Chip 
                  variant="soft" 
                  color="primary" 
                  size="sm"
                >
                  Syllabus
                </Chip>
              )}
              <Typography level="body-md">{row.question}</Typography>
            </Stack>
        </td>
        <td className="student-response-column">
            {row.rating !== null && row.rating !== undefined && (
              <span 
                role="img" 
                aria-label={getRatingLabel(row.rating)}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {row.rating === true ? 
                  <ThumbUpIcon aria-hidden="true" /> : 
                  <ThumbDownIcon aria-hidden="true" />
                }
              </span>
            )}
        </td>
        <td className="answer-column">
          <Button
            aria-label="expand row"
            variant="plain"
            color="neutral"
            size="sm"
            data-testid="answer-button"
            onClick={() => setOpen(!open)}
          >
            Answer
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </td>
      </tr>
      <tr>
        <td style={{ height: 0, padding: 0, textAlign: 'left' }} colSpan={6}>
          {open && (
            <Sheet
              variant="plain"
              sx={{ p: 2, boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 0.08)', wordWrap:'break-word', }}
              data-testid="answer-content"
            >
              <Typography level="body-md">
                <LatexRenderer text={row.answer} />
              </Typography>
              <Button variant="soft" color="neutral" onClick={handleCreateNoteClick} sx={{mt:1}}>
                {row.note_id ? "View & edit your answer" : "Add an answer"}
              </Button>
            </Sheet>
          )}
        </td>
      </tr>
    </>
  );
}


//This section is used for pagination
function labelDisplayedRows({ from, to, count }) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

//This section handles sorting the table
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'rating') {
    // Convert boolean/null to numeric values for comparison
    const ratingValue = (rating) => {
      if (rating === true) return 3;
      if (rating === null || rating === undefined) return 2;
      if (rating === false) return 1;
      return 0; // for null/undefined ratings
    };
    return ratingValue(b[orderBy]) - ratingValue(a[orderBy]);
  }
  if (orderBy === 'id') {
    return b.id - a.id;  // For numeric IDs
  }
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    return b[orderBy].localeCompare(a[orderBy]);
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export default function TableCollapsibleRow( { questionAndAnswers }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState(questionAndAnswers);
  const [sortOrder, setSortOrder] = useState({ field: 'id', direction: 'desc' });
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);
  const [noteTemplate, setNoteTemplate] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setRows(questionAndAnswers);

  }, [questionAndAnswers]);

  //This section is used for pagination
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(0);
  };

  const handleCreateNoteClick = (row) => {
    let note_title = row.question;
    if (note_title.length > 50) {
      note_title = note_title.substring(0, 50) + "...";
    }
    const note_text = `Question:\n${row.question}\n\nAnswer:\n`;

    const note_template = {
      title: note_title,
      text: note_text,
    };

    setNoteTemplate(note_template);
    setSelectedQuestion(row);
    setCreateNoteModalOpen(true);
  };

  const handleNoteCreated = (file_id) => {
    setRows(rows.map(row => row.id === selectedQuestion.id ? { ...row, note_id: file_id } : row));
    setSnackbarOpen(true);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? rows.length
      : Math.min(rows.length, (page + 1) * rowsPerPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Sheet variant="plain">
      {/* Table Sort and Pagination */} 
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" mb={2} className="table-toolbar">
        <FormControl>
          <FormLabel>Sort by:</FormLabel>
            <Select
              value={`${sortOrder.field}-${sortOrder.direction}`}
              onChange={(_, newValue) => {
                const [field, direction] = newValue.split('-');
                setSortOrder({ field, direction });
              }}
              sx={{ minWidth: 200 }}
              variant='plain'
            >
              <Option value="id-desc">Most Recent</Option>
              <Option value="id-asc">Oldest</Option>
              <Option value="rating-desc">Rating: Positive First</Option>
              <Option value="rating-asc">Rating: Negative First</Option>
              <Option value="question-asc">Alphabetically A-Z</Option>
              <Option value="question-desc">Alphabetically Z-A</Option>
            </Select>
        </FormControl>
        { /* Pagination Component */}
        <Stack direction="row" alignItems="center" gap={2}>
          <FormControl orientation="horizontal" size="md">
            <FormLabel>Rows/Page:</FormLabel>
            <Select onChange={handleChangeRowsPerPage} size="sm" value={rowsPerPage}>
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={25}>25</Option>
            </Select>
          </FormControl>
          <Typography level="body-sm" sx={{ fontFamily: 'UbuntuMedium'}}>
            {labelDisplayedRows({
              from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
              to: getLabelDisplayedRowsTo(),
              count: rows.length === -1 ? -1 : rows.length,
            })}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Previous Page"
              disabled={page === 0}
              onClick={() => handleChangePage(page - 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              aria-label="Next Page"
              disabled={
                rows.length !== -1
                  ? page >= Math.ceil(rows.length / rowsPerPage) - 1
                  : false
              }
              onClick={() => handleChangePage(page + 1)}
              sx={{ bgcolor: "background.surface" }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Stack>
        { /* End Pagination Component */}
      </Stack>
      <Divider />
      {/* Table Body Rows */} 
      <Table
        hoverRow
        aria-label="collapsible table"
        size="md"
      >
        <tbody>
        {stableSort(rows, getComparator(sortOrder.direction, sortOrder.field))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <Row key={row.id} row={row} initialOpen={index === 0} handleCreateNoteClick={() => handleCreateNoteClick(row)} />
          ))}
          {emptyRows > 0 && (
            <tr
              style={{
                height: `calc(${emptyRows} * 40px)`,
                "--TableRow-hoverBackground": "transparent",
              }}
            >
              <td colSpan={4} aria-hidden />
            </tr>
          )}
        </tbody>
      </Table>
      <CreateNoteModal 
        open={createNoteModalOpen}
        onClose={() => setCreateNoteModalOpen(false)}
        refresh={(file_id) => handleNoteCreated(file_id)}
        edit={selectedQuestion?.note_id ? true : false}
        file_id={selectedQuestion?.note_id}
        note_template={noteTemplate}
        question_id={selectedQuestion?.id}
      />
      <Snackbar
        autoHideDuration={3000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        color="success"
        variant="soft"
        role="alert"
        aria-live="polite"
      >
          Successfully updated answer!
      </Snackbar>
    </Sheet>
  );
}