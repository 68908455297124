const subscriptionTiers = {
  1: {
    name: 'Trial',
    features: [
      'Upload up to 10 documents',
      'Share a public link with students'
    ],
    price: 0,
    billingPeriod: 'for 14 days',
    maxStudents: 0,
    maxQuestions: 2500
  },
  2: {
    name: 'Small Course',
    features: [
      'Recommended for courses with 50 or less students',
      'Answer up to 2,500 student questions',
    ],
    price: 50,
    currency: 'USD',
    fullPrice: 100,
    billingPeriod: 'per term',
    maxStudents: 50,
    maxQuestions: 2500
  },
  3: {
    name: 'Medium Course',
    features: [
      'Recommended for courses with 50 to 100 students',
      'Answer up to 5,000 student questions',
    ],
    price: 100,
    fullPrice: 200,
    currency: 'USD',
    billingPeriod: 'per term',
    maxStudents: 100,
    maxQuestions: 5000
  },
  4: {
    name: 'Large Course',
    features: [
      'Recommended for courses with 100 to 200 students',
      'Answer up to 10,000 student questions'
    ],
    price: 200,
    fullPrice: 400,
    currency: 'USD',
    billingPeriod: 'per term',
    maxStudents: 200,
    maxQuestions: 10000
  },
  5: {
    name: 'Institutional or Department License',
    features: [
      'Implement All Day TA across your whole department or institution'
    ],
  }
};

// Helper function to find tier details by ID
export const getTierDetails = (tierId) => {
  if (tierId == null) return null;
  
  // Convert to integer if string number is provided
  const id = parseInt(tierId, 10);
  return subscriptionTiers[id] || null;
};

// Get all available tier IDs
export const getAllTierIds = () => {
  return Object.keys(subscriptionTiers).map(id => parseInt(id, 10));
};

export default subscriptionTiers;