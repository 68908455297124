import React from 'react';
import { 
  Box,
  Button, 
  Card,
  Skeleton,
  Stack, 
  Typography
} from '@mui/joy';
import QuestionCard from './QuestionCard';

const generateQuestionKey = (question) => {
  return `q-${question.course_id}-${question.question_id}-${question.timestamp}`;
};

const ITEMS_PER_PAGE = 10;

const QuestionsList = React.memo(({ filteredQuestions, totalQuestions, isLoading, currentPage, onPageChange, hasMoreQuestions }) => {
  const displayedCount = filteredQuestions.length;
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE + 1;
  const endIndex = Math.min(currentPage * ITEMS_PER_PAGE, totalQuestions);

  return (
    <Card variant="outlined">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="h2" sx={{ mb: 2 }}>
          {isLoading ? (
            <Skeleton variant="text" width={200} />
          ) : (
            `Questions (${displayedCount}/${totalQuestions})`
          )}
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
            <Button 
              variant="outlined"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1 || isLoading}
            >
              Previous
            </Button>
            
            {isLoading ? (
              <Skeleton variant="text" width={100} />
            ) : (
              <Typography level="body-md">
                {startIndex}-{endIndex} of {totalQuestions}
              </Typography>
            )}
            
            <Button 
              variant="outlined"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={!hasMoreQuestions || isLoading}
            >
              Next
            </Button>
          </Stack>
      </Stack>
      <Box sx={{ mt: 2 }}>
        {filteredQuestions.map((question) => (
          <QuestionCard 
            key={generateQuestionKey(question)} 
            question={question}
            isLoading={isLoading}
          />
        ))}
        {filteredQuestions?.length === 0 && !isLoading && (
          <Typography level="body-lg" sx={{ textAlign: 'center', py: 4 }}>
            No questions found
          </Typography>
        )}
      </Box>
      {/* {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress />
        </Box>
      )} */}
    </Card>
  );
});

QuestionsList.displayName = 'QuestionsList';
export default QuestionsList;
