import axios from 'axios';
import { useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  ModalDialog,
  Typography,
  Link
} from '@mui/joy';

export default function TermsAcceptanceModal({ open, onClose }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAcceptTerms = async () => {
    setIsSubmitting(true);
    try {
      await axios.post('/api/accept_terms');
      onClose();
    } catch (error) {
      console.error('Error accepting terms:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <Modal
      open={open}
      onClose={() => {}} // Empty function prevents closing on backdrop click
      disableEscapeKeyDown // Prevent closing with Escape key
      sx={{ zIndex: 1500 }}
    >
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        aria-labelledby="terms-modal-title"
        aria-describedby="terms-modal-desc"
      >
        <DialogTitle id="terms-modal-title">Terms & Conditions</DialogTitle>
        <DialogContent id="terms-modal-desc">
          <Typography level="body-md" mb={2}>
            Please review and accept our terms and conditions to continue using All Day TA.
          </Typography>
          <Link
            href="https://www.alldayta.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
          >
            Read our full terms and conditions
          </Link>
        </DialogContent>
        <DialogActions>
          <Button
            variant="solid"
            color="primary"
            onClick={handleAcceptTerms}
            loading={isSubmitting}
          >
            I Accept
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}